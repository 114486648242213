:root {
  --background-blue: #f5f7fc;
  --bodycopy-font-family: "Inter-regular", sans-serif;
  --bodycopy-font-size: 16px;
  --bodycopy-font-style: normal;
  --bodycopy-font-weight: 400;
  --bodycopy-large-font-family: "Inter-regular", sans-serif;
  --bodycopy-large-font-size: 18px;
  --bodycopy-large-font-style: normal;
  --bodycopy-large-font-weight: 400;
  --bodycopy-large-letter-spacing: 0px;
  --bodycopy-large-line-height: 133.333%;
  --bodycopy-letter-spacing: 0px;
  --bodycopy-line-height: 150%;
  --cta: #27f;
  --double-shadow: 0px 2px 16px 0px #0000001f, 0px 4px 6px 0px #0000001a, 0px 2px 3px 0px #0000001a;
  --double-shadow-transparent: 0px 2px 16px 0px #0000, 0px 4px 6px 0px #0000, 0px 2px 3px 0px #0000;
  --gray-400: #96a4b2;
  --gray-800: #5f6b77;
  --h-1-font-family: "Inter-Bold", sans-serif;
  --h-1-font-size: 48px;
  --h-1-font-style: normal;
  --h-1-font-weight: 700;
  --h-1-letter-spacing: 0px;
  --h-1-line-height: 115.0%;
  --h-2-font-family: "Inter-Bold", sans-serif;
  --h-2-font-size: 32px;
  --h-2-font-style: normal;
  --h-2-font-weight: 700;
  --h-2-letter-spacing: 0px;
  --h-2-line-height: 115.0%;
  --h-3-font-family: "Inter-Bold", sans-serif;
  --h-3-font-size: 32px;
  --h-3-font-style: normal;
  --h-3-font-weight: 700;
  --h-3-letter-spacing: 0px;
  --h-3-line-height: 115.0%;
  --h-4-font-family: "Inter-Bold", sans-serif;
  --h-4-font-size: 24px;
  --h-4-font-style: normal;
  --h-4-font-weight: 700;
  --h-4-letter-spacing: 0px;
  --h-4-line-height: 115.0%;
  --intro-font-family: "Inter-Regular", sans-serif;
  --intro-font-size: 20px;
  --intro-font-style: normal;
  --intro-font-weight: 400;
  --intro-letter-spacing: 0px;
  --intro-line-height: 120%;
  --primary: #47b;
  --primary-darker: #258;
  --primary-darkest: #035;
  --primary-lighter: #79c;
  --primary-lightest: #bce;
  --purple-1: #684db0;
  --purple-light: #a591d7;
  --secondary: #387;
  --secondary-dark: #276;
  --secondary-light: #bdd;
  --subtitle-font-family: "Inter-Regular", sans-serif;
  --subtitle-font-size: 24px;
  --subtitle-font-style: normal;
  --subtitle-font-weight: 400;
  --subtitle-letter-spacing: 0px;
  --subtitle-line-height: 120%;
  --tb-darkblue: #2f5496;
  --tb-lightblue: #b4c6e7;
  --white: #fff;
}

.button {
  all: unset;
  box-sizing: border-box;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 16px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.button .request-a-demo {
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}

.button.outline {
  border: 1px solid;
}

.button.outline.normal {
  background-color: var(--white);
  border-color: var(--primary-darker);
  box-shadow: var(--double-shadow-transparent);
}

.button.cta.normal {
  box-shadow: var(--double-shadow-transparent);
}

.button.hover.outline {
  background-color: var(--primary);
  border-color: var(--primary);
}

.button.hover.cta {
  box-shadow: var(--double-shadow);
}

.button.regular.normal.solid {
  background-color: var(--primary-darker);
}

.button.cta.text.normal, .button.cta.normal.solid {
  background-color: #4826a0;
}

.button.hover.cta.solid {
  background-color: var(--purple-1);
}

.button.hover.regular.solid, .button.hover.regular.outline {
  background-color: var(--primary);
}

.button.hover.text.cta {
  background-color: var(--purple-1);
}

.button.solid .request-a-demo {
  color: #fff;
}

.button.outline.normal .request-a-demo {
  color: var(--primary-darker);
}

.button.hover.outline .request-a-demo {
  color: var(--white);
}

.button.hover.text .request-a-demo {
  color: var(--primary);
}

.button.text.normal .request-a-demo {
  color: var(--gray-800);
}

.quote {
  background-color: var(--white);
  border-radius: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  padding: 16px 24px 24px;
  display: flex;
  position: relative;
}

.quote .TOP {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  min-height: 152px;
  display: flex;
  position: relative;
}

.quote .img {
  flex: none;
  position: relative;
}

.quote .for-our-mobile-app-wrapper {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 10px;
  padding: 8px 0 0;
  display: flex;
  position: relative;
}

.quote .for-our-mobile-app {
  color: var(--gray-800);
  font-family: var(--bodycopy-large-font-family);
  font-size: var(--bodycopy-large-font-size);
  font-style: var(--bodycopy-large-font-style);
  font-weight: var(--bodycopy-large-font-weight);
  letter-spacing: var(--bodycopy-large-letter-spacing);
  line-height: var(--bodycopy-large-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.quote .frame-2 {
  flex: none;
  align-self: stretch;
  position: relative;
}

.quote .BOTTOM {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.quote .div {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.quote .nilay-kose {
  color: var(--primary-darkest);
  font-family: var(--subtitle-font-family);
  font-size: var(--subtitle-font-size);
  font-style: var(--subtitle-font-style);
  font-weight: var(--subtitle-font-weight);
  letter-spacing: var(--subtitle-letter-spacing);
  line-height: var(--subtitle-line-height);
  align-self: stretch;
  position: relative;
}

.quote .test-and-quality {
  color: var(--gray-800);
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
  align-self: stretch;
  position: relative;
}

.quote .avatar-1 {
  width: 80px !important;
  height: 80px !important;
  position: relative !important;
}

.MAIN {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.MAIN .HEADER-CONTENT-wrapper {
  background-color: var(--white);
  z-index: 100;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 12px 0;
  display: flex;
  position: sticky;
  top: 0;
}

.MAIN .design-component-instance-node {
  flex: none !important;
  padding: 8px 0 !important;
}

.MAIN .button-2 {
  cursor: pointer;
  flex: none !important;
}

.MAIN .frame-wrapper {
  background: linear-gradient(248.04deg, #bce -1.75%, #bcade1 110.22%);
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  width: 100%;
  padding: 96px 0;
  display: flex;
  position: relative;
}

.MAIN .div-2 {
  flex: none;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  max-width: 1280px;
  display: flex;
  position: relative;
}

.MAIN .div-wrapper {
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
  align-items: flex-start;
  gap: 10px;
  padding: 0 64px;
  display: flex;
  position: relative;
}

.MAIN .new-line {
  width: 100%;
  max-width: 405px;
  margin-right: 10px;
  display: flex;
}

.MAIN .new-line-2 {
  width: 100%;
  max-width: 636px;
  margin-bottom: 10px;
  display: flex;
}

@keyframes drawHighlight {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.MAIN .highlight-container, .highlight {
  position: relative;
}

.MAIN .highlight-container {
  display: inline-block;
  overflow: hidden;
}

.MAIN .highlight-container:before {
  content: " ";
  background: #b6d0e2;
  border-radius: 20% 25% 20% 24%;
  width: 0;
  height: 90%;
  padding: 10px 3px 3px 10px;
  animation: 1s forwards drawHighlight;
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  transform: rotate(2deg);
}

.MAIN .automation-text {
  flex-direction: row;
  display: flex;
}

.MAIN .img-wrapper {
  justify-content: center;
  max-width: 473px;
  max-height: 355px;
  display: flex;
  position: relative;
}

.MAIN .modal {
  z-index: 1050;
  background-color: #000c;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.MAIN .modal-video {
  max-width: 90%;
  max-height: 90vh;
}

.MAIN .placeholder {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 500px;
  display: flex;
  position: relative;
}

.MAIN .play-button {
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.MAIN .frame-4 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.MAIN .text-wrapper-3 {
  color: var(--primary-darkest);
  font-family: var(--h-1-font-family);
  font-size: 52px;
  font-style: var(--h-1-font-style);
  font-weight: var(--h-1-font-weight);
  letter-spacing: var(--h-1-letter-spacing);
  line-height: var(--h-1-line-height);
  margin-top: -1px;
  position: relative;
}

.MAIN .p {
  color: var(--gray-800);
  font-family: var(--intro-font-family);
  font-size: var(--intro-font-size);
  font-style: var(--intro-font-style);
  font-weight: var(--intro-font-weight);
  letter-spacing: var(--intro-letter-spacing);
  line-height: var(--intro-line-height);
  max-width: 550px;
  position: relative;
}

.MAIN .worker {
  max-width: 100%;
  padding-right: 1%;
}

.MAIN .frame-5 {
  background-color: var(--white);
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 24px 10px 48px;
  display: flex;
  position: relative;
}

.MAIN .frame-6 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 32px;
  width: 100%;
  max-width: 1084px;
  display: inline-flex;
  position: relative;
}

.MAIN .text-wrapper-4 {
  color: var(--primary-darkest);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.MAIN .frame-7 {
  flex-direction: row;
  flex: none;
  max-width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.scroller {
  max-width: 100%;
}

.scroller[data-animated="true"] {
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg, #0000, #fff 20% 80%, #0000);
  mask: linear-gradient(90deg, #0000, #fff 20% 80%, #0000);
}

.scroller-inner {
  display: flex;
}

.scroller[data-animated="true"] .scroller-inner {
  animation: scroll var(--_animation-duration, 40s) var(--_animation-direction, forwards) linear infinite;
  flex-wrap: nowrap;
  width: max-content;
}

@keyframes scroll {
  to {
    transform: translate(-50%);
  }
}

.MAIN .img-wrapper2 {
  width: 256px;
  height: 50px;
  position: relative;
}

.MAIN .companies {
  filter: grayscale();
  max-width: 100%;
  transition: filter .3s;
}

.MAIN .companies:hover {
  filter: none;
}

.MAIN .frame-8 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  width: 100%;
  padding: 72px 0;
  display: flex;
  position: relative;
}

.MAIN .text-wrapper-5 {
  color: var(--primary-darkest);
  font-family: var(--h-2-font-family);
  font-size: var(--h-2-font-size);
  font-style: var(--h-2-font-style);
  font-weight: var(--h-2-font-weight);
  letter-spacing: var(--h-2-letter-spacing);
  line-height: var(--h-2-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.MAIN .frame-9-copy {
  grid-template-rows: auto auto;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  gap: 64px;
  max-width: 1152px;
  margin: auto;
  display: grid;
}

.MAIN .card-upper, .MAIN .card-down {
  flex-direction: column;
  flex: 1;
  place-content: center space-between;
  align-items: center;
  display: flex;
}

.MAIN .CARD-2-copy {
  box-shadow: var(--double-shadow);
  transform-origin: center;
  background-color: #f5f7fc;
  border: 1px solid #f5f7fc;
  border-radius: 16px;
  flex-direction: column;
  flex-grow: 1;
  align-self: center;
  align-items: center;
  width: 400px;
  height: 448px;
  padding: 32px;
  transition: transform 10ms;
  display: flex;
  position: relative;
}

.MAIN .CARD-2 {
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--white);
  box-shadow: var(--double-shadow);
  transform-origin: center;
  border-radius: 16px;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: center;
  gap: 48px;
  width: 341px;
  padding: 32px;
  transition: transform 10ms;
  display: flex;
  position: relative;
}

.CARD-2-copy:hover {
  transform: translate(0, -3px);
  box-shadow: 0 0 15px #0000004d;
}

.CARD-2-copy:hover .button {
  background-color: var(--primary-darker);
}

.CARD-2-copy:hover .button .request-a-demo {
  color: var(--white);
}

.MAIN .CARD-5 {
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--white);
  box-shadow: var(--double-shadow);
  transform-origin: center;
  border-radius: 16px;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: center;
  gap: 48px;
  padding: 32px;
  transition: transform 10ms;
  display: flex;
  position: relative;
}

.CARD-5:hover {
  transform: translate(0, -3px);
  box-shadow: 0 0 15px #00000086;
}

.MAIN .frame-10 {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  min-height: 198px;
  display: flex;
  position: relative;
}

.MAIN .frame-32 {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  gap: 48px;
  width: 100%;
  display: flex;
  position: relative;
}

.MAIN .frame-32-copy {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.MAIN .starter {
  color: var(--primary-darkest);
  font-family: var(--h-4-font-family);
  font-size: var(--h-4-font-size);
  font-style: var(--h-4-font-style);
  font-weight: var(--h-4-font-weight);
  letter-spacing: var(--h-4-letter-spacing);
  line-height: var(--h-4-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.MAIN .icon-instance-node {
  width: 128px !important;
  height: 128px !important;
  position: relative !important;
}

.MAIN .icon-instance-node-2 {
  padding: 1.5%;
  width: 128px !important;
  height: 128px !important;
  position: relative !important;
}

.MAIN .automate-mobile-and-2 {
  color: var(--gray-800);
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
  text-align: center;
  align-self: stretch;
  min-height: 144px;
  position: relative;
}

.MAIN .automate-mobile-and-2-copy {
  color: var(--gray-800);
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.MAIN .design-component-instance-node-2, .MAIN .design-component-instance-node-2-large {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.MAIN .CARD-3 {
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--white);
  box-shadow: var(--double-shadow);
  transform-origin: center;
  border-radius: 16px;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: center;
  gap: 48px;
  padding: 32px;
  transition: transform .3s;
  display: flex;
  position: relative;
}

.CARD-3:hover {
  transform: scale(1.009);
  box-shadow: 0 0 10px #0000004d;
}

.MAIN .frame-11 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.MAIN .frame-12 {
  background: linear-gradient(297.87deg, #bce -13.79%, #fff 116.74%);
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  width: 100%;
  padding: 72px 0;
  display: flex;
  position: relative;
}

.MAIN .frame-13 {
  flex: none;
  align-items: flex-start;
  gap: 64px;
  width: 100%;
  max-width: 1152px;
  display: flex;
  position: relative;
}

.MAIN .frame-14 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 260px;
  display: flex;
  position: relative;
}

.MAIN .text-wrapper-6 {
  color: var(--primary-darkest);
  font-family: var(--h-2-font-family);
  font-size: var(--h-2-font-size);
  font-style: var(--h-2-font-style);
  font-weight: var(--h-2-font-weight);
  letter-spacing: var(--h-2-letter-spacing);
  line-height: var(--h-2-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.MAIN .text-wrapper-7 {
  color: var(--primary-darkest);
  font-family: var(--subtitle-font-family);
  font-size: var(--subtitle-font-size);
  font-style: var(--subtitle-font-style);
  font-weight: var(--subtitle-font-weight);
  letter-spacing: var(--subtitle-letter-spacing);
  line-height: var(--subtitle-line-height);
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.MAIN .frame-15 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 64px;
  width: 100%;
  display: flex;
  position: relative;
}

.MAIN .CARD-4 {
  border-radius: 16px;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.MAIN .frame-16 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 8px;
  display: flex;
  position: relative;
}

.MAIN .text-wrapper-8 {
  color: var(--primary-darkest);
  font-family: var(--h-4-font-family);
  font-size: var(--h-4-font-size);
  font-style: var(--h-4-font-style);
  font-weight: var(--h-4-font-weight);
  letter-spacing: var(--h-4-letter-spacing);
  line-height: var(--h-4-line-height);
  align-self: stretch;
  width: 100%;
  margin-top: -1px;
  position: relative;
}

.MAIN .text-wrapper-9 {
  color: var(--gray-800);
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
  align-self: stretch;
  width: 100%;
  position: relative;
}

.MAIN .frame-17 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.MAIN .frame-18 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 64px;
  max-width: 1152px;
  padding: 0 64px;
  display: flex;
  position: relative;
}

.MAIN .quote-instance {
  flex: 1;
  border: 1px solid !important;
  border-color: var(--gray-400) !important;
  align-self: stretch !important;
  max-width: 480px !important;
}

.MAIN .quote-2 {
  font-family: var(--bodycopy-large-font-family) !important;
  font-size: var(--bodycopy-large-font-size) !important;
  font-style: var(--bodycopy-large-font-style) !important;
  font-weight: var(--bodycopy-large-font-weight) !important;
  letter-spacing: var(--bodycopy-large-letter-spacing) !important;
  line-height: var(--bodycopy-large-line-height) !important;
}

.MAIN .quote-wrapper {
  flex-direction: row;
  gap: 64px;
  display: flex;
}

.MAIN .property-1-male {
  width: 80px !important;
  height: 80px !important;
  position: relative !important;
}

.MAIN .frame-19 {
  background: linear-gradient(102.3deg, #684db0 0%, #258 100%);
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  width: 100%;
  padding: 72px 0;
  display: flex;
  position: relative;
}

.MAIN .frame-20 {
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.MAIN .text-wrapper-10 {
  color: var(--white);
  font-family: var(--h-2-font-family);
  font-size: var(--h-2-font-size);
  font-style: var(--h-2-font-style);
  font-weight: var(--h-2-font-weight);
  letter-spacing: var(--h-2-letter-spacing);
  line-height: var(--h-2-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.MAIN .text-wrapper-11 {
  color: var(--white);
  font-family: var(--subtitle-font-family);
  font-size: var(--subtitle-font-size);
  font-style: var(--subtitle-font-style);
  font-weight: var(--subtitle-font-weight);
  letter-spacing: var(--subtitle-letter-spacing);
  line-height: var(--subtitle-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.MAIN .frame-21 {
  flex: none;
  align-items: center;
  gap: 64px;
  max-width: 1152px;
  padding: 0 64px;
  display: flex;
  position: relative;
}

.MAIN .design-component-instance-node-3 {
  width: unset !important;
  flex: 1 !important;
  align-self: stretch !important;
}

.MAIN .automate-mobile-and-3 {
  color: #0000;
  letter-spacing: 0;
  align-self: stretch;
  min-height: 144px;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.MAIN .text-wrapper-12 {
  color: #258;
  font-weight: 600;
}

.MAIN .text-wrapper-13 {
  color: #5f6b77;
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
}

.MAIN .frame-22 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 64px;
  width: 750px;
  display: flex;
  position: relative;
}

.MAIN .frame-23 {
  background-color: var(--background-blue);
  box-shadow: var(--double-shadow);
  border-radius: 16px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 32px;
  display: flex;
  position: relative;
}

.MAIN .frame-24 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.MAIN .text-wrapper-14 {
  color: var(--primary-darkest);
  font-family: var(--subtitle-font-family);
  font-size: var(--subtitle-font-size);
  font-style: var(--subtitle-font-style);
  font-weight: var(--subtitle-font-weight);
  letter-spacing: var(--subtitle-letter-spacing);
  line-height: var(--subtitle-line-height);
  align-self: stretch;
  position: relative;
}

.MAIN .frame-25 {
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-end;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.MAIN .frame-26 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.MAIN .frame-27 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 32px;
  display: flex;
  position: relative;
}

.MAIN .input-label-instance {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.comment-bottom-2 {
  flex-direction: row;
  justify-content: center;
  align-self: stretch;
  gap: 10px;
  display: flex;
}

.capterra-2 {
  align-self: flex-end;
  width: 100%;
  min-width: 77px;
  max-width: 120px;
  display: flex;
}

.capterra-2 img {
  filter: grayscale();
}

.capterra-2 img:hover {
  filter: none;
}

.getapp-2 {
  align-self: flex-start;
  width: 100%;
  min-width: 60px;
  max-width: 80px;
}

.getapp-2 img {
  filter: grayscale();
}

.getapp-2 img:hover {
  filter: none;
}

.getapp-img-2 {
  width: 100%;
}

.comment-bottom-2 img {
  filter: grayscale(50%);
}

.comment-bottom-2 img:hover {
  filter: none;
}

.MAIN .input-label-wrapper {
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  display: flex;
  position: relative;
}

.MAIN .input-label-2 {
  flex: 1 !important;
  align-self: stretch !important;
  align-items: flex-start !important;
  width: 100% !important;
  display: flex !important;
}

.MAIN .input-label-3 {
  margin-right: -1px !important;
}

.MAIN .frame-28 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.MAIN .input-label-4 {
  width: unset !important;
  flex: 1 !important;
}

.MAIN .tb-1-1 {
  width: 109.03px !important;
  height: 40.44px !important;
  position: relative !important;
}

.MAIN .text-wrapper-15 {
  color: var(--gray-400);
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.MAIN .text-wrapper-16 {
  color: var(--gray-400);
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
  width: 83px;
  margin-top: -1px;
  position: relative;
}

.MAIN .text-wrapper-17 {
  color: var(--white);
  letter-spacing: 0;
  align-self: stretch;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: underline;
  position: relative;
}

@media screen and (width <= 1210px) {
  .MAIN .footer {
    gap: 120px;
  }

  .MAIN .frame-13 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .MAIN .frame-16 {
    justify-content: center;
    align-items: center;
  }

  .MAIN .text-wrapper-6 {
    text-align: center;
    font-size: var(--h-1-font-size);
    justify-content: center;
    align-self: center;
  }

  .MAIN .frame-14 {
    align-items: center;
    width: 80%;
  }

  .MAIN .frame-15 {
    align-items: center;
    width: 100%;
  }

  .MAIN .CARD-4 {
    align-self: center;
    width: 80%;
  }
}

@media screen and (width <= 1190px) {
  .MAIN .frame-9-copy {
    gap: 50px;
  }
}

@media screen and (width <= 1152px) {
  .MAIN .frame-9-copy {
    gap: 40px;
  }

  .MAIN .frame-21 {
    gap: 48px;
  }

  .MAIN .footer {
    gap: 70px;
  }
}

@media screen and (width <= 1140px) {
  .MAIN .frame-9-copy {
    gap: 30px;
  }

  .MAIN .frame-21 {
    gap: 36px;
  }
}

@media screen and (width <= 1120px) {
  .MAIN .frame-9-copy {
    gap: 20px;
  }

  .MAIN .frame-21 {
    gap: 24px;
  }
}

@media screen and (width <= 1100px) {
  .MAIN .frame-9-copy {
    flex-direction: column;
    gap: 36px;
    padding: 0 8%;
    display: flex;
  }

  .MAIN .frame-18 {
    gap: 36px;
  }

  .MAIN .CARD-2-copy {
    align-self: center;
    width: 100%;
    height: 500px;
  }

  .MAIN .frame-21 {
    flex-direction: column;
  }
}

@media screen and (width <= 1050px) {
  .MAIN .frame-18, .MAIN .quote-wrapper {
    gap: 24px;
  }
}

@media screen and (width <= 1040px) {
  .MAIN .quote-wrapper, .MAIN .frame-18 {
    flex-direction: column;
  }

  .MAIN .quote-instance {
    max-width: 480px !important;
  }
}

@media screen and (width <= 960px) {
  .MAIN .footer {
    gap: 40px;
  }

  .MAIN .frame-28 {
    flex-direction: column;
  }

  .MAIN .input-label-4 {
    width: 100% !important;
  }

  .MAIN .HEADER-CONTENT-wrapperItems {
    position: relative;
  }

  .MAIN .design-component-instance-node-large, .MAIN .button-2-large {
    display: none;
  }

  .menu-container {
    display: inline-block;
    position: relative;
  }

  .menu-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .menu-list li {
    padding: 8px 16px;
  }

  .menu-list li a {
    color: #333;
    text-decoration: none;
    display: block;
  }

  .menu-list li a:hover {
    background-color: #f0f0f0;
  }

  .MAIN .div-wrapper {
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 5%;
    display: flex;
    position: relative;
  }
}

@media screen and (width <= 860px) {
  .MAIN .frame-18, .MAIN .frame-21 {
    padding: 0 8%;
  }

  .MAIN .text-wrapper-15 {
    text-align: center;
  }

  .MAIN .frame-8 {
    padding: 72px 5%;
  }

  .MAIN .footer {
    flex-direction: column;
  }

  .MAIN .frame-22 {
    width: 100%;
    padding: 0% 5%;
    max-width: 750px !important;
  }

  .home__hero-text-wrapper {
    flex-direction: column;
    align-items: center;
    padding-bottom: 65px;
    display: flex;
  }

  .MAIN .div-wrapper {
    text-align: center;
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5%;
    display: flex;
    position: relative;
  }

  .scroller .scroller-inner {
    flex-direction: column;
    flex: none;
    justify-content: center;
    align-items: center;
    gap: 24px;
    display: flex;
    position: relative;
  }

  .scroller[data-animated="true"] .scroller-inner {
    animation: scroll var(--_animation-duration, 40s) var(--_animation-direction, forwards) linear infinite;
    flex-flow: row;
    width: max-content;
  }

  .MAIN .img-wrapper {
    align-items: center;
    max-width: 473px;
    max-height: 355px;
    margin-top: 8%;
    position: relative;
  }

  .MAIN .worker {
    align-items: center;
    max-width: 80%;
    margin: 0 0 0 10px;
    padding-right: 0;
  }

  .MAIN .div-2 {
    flex-direction: column;
    flex: none;
    justify-content: space-between;
    align-items: center;
    max-width: 1152px;
    display: flex;
    position: relative;
  }

  .MAIN .frame-4 {
    align-items: center;
  }

  .MAIN .img-wrapper2 {
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
  }

  .MAIN .text-wrapper-4 {
    font-size: 25px;
  }
}

@media screen and (width <= 780px) {
  .scroller[data-animated="true"] .img-wrapper2 {
    width: 192px;
  }
}

@media screen and (width <= 500px) {
  .MAIN .automation-text {
    flex-direction: column;
  }

  .scroller[data-animated="true"] .img-wrapper2 {
    width: 140px;
  }
}

@media screen and (width <= 430px) {
  .MAIN .HEADER-CONTENT-wrapper {
    background-color: var(--white);
    z-index: 100;
    flex-direction: column;
    flex: none;
    justify-content: center;
    align-self: stretch;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 12px 0;
    display: flex;
    top: 0;
  }

  .MAIN .div-2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }

  .MAIN .div-wrapper {
    width: 100%;
    padding: 0 16px;
  }

  .MAIN .text-wrapper-3 {
    text-align: center;
    margin-top: 8px;
    font-size: 32px;
  }

  .MAIN .text-wrapper-4 {
    text-align: center;
    margin-top: 8px;
    font-size: 16px;
  }

  .MAIN .frame-5 {
    flex-direction: column;
    gap: 8px;
    padding: 16px 0 24px;
  }

  .MAIN .frame-6 {
    gap: 24px;
    max-width: 90%;
  }

  .MAIN .CARD-2-copy, .MAIN .CARD-2, .MAIN .CARD-3, .MAIN .CARD-4, .MAIN .CARD-5 {
    width: 100%;
    margin-bottom: 16px;
    padding: 16px;
  }

  .MAIN .frame-9-copy {
    flex-direction: column;
    gap: 24px;
    display: flex;
  }

  .MAIN .quote-wrapper, .MAIN .frame-18 {
    flex-direction: column;
    gap: 16px;
  }

  .MAIN .img-wrapper {
    max-width: 100%;
    max-height: 200px;
  }

  .MAIN .footer {
    flex-direction: column;
    gap: 16px;
    padding: 16px;
  }

  .MAIN .frame-8 {
    padding: 48px 16px;
  }

  .MAIN .text-wrapper-5 {
    font-size: 20px;
  }

  .MAIN .frame-22 {
    width: 100%;
    padding: 0 16px;
  }

  .MAIN .text-wrapper-15 {
    text-align: center;
    font-size: 14px;
  }

  .scroller .scroller-inner {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .MAIN .frame-23 {
    gap: 16px;
    padding: 24px;
  }

  .MAIN .frame-24, .MAIN .frame-25, .MAIN .frame-26 {
    padding: 16px;
  }

  .MAIN .frame-27 {
    gap: 16px;
  }

  .MAIN .frame-28 {
    flex-direction: column;
    gap: 16px;
  }

  .MAIN .placeholder {
    cursor: pointer;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
    display: flex;
    position: relative;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.HEADER {
  background-color: var(--white);
  z-index: 100;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 12px 0;
  display: flex;
  position: sticky;
  top: 0;
}

.HEADER .HEADER-CONTENT {
  background-color: var(--white);
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1152px;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.HEADER .HEADER-CONTENT-wrapper {
  background-color: var(--white);
  z-index: 100;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 12px 0;
  display: flex;
  position: sticky;
  top: 0;
}

.HEADER .HEADER-CONTENT-wrapperItems {
  position: relative;
}

.HEADER .tb {
  width: 109.03px !important;
  height: 40.44px !important;
  margin-top: -.21px !important;
  margin-bottom: -.23px !important;
  position: relative !important;
}

.HEADER .frame-3 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.HEADER .button-2-large {
  cursor: pointer;
  flex: none !important;
}

.HEADER .menu-icon, .menu-dropdown {
  display: none;
}

@media screen and (width <= 1180px) {
  .HEADER .tb {
    top: 0;
    left: 0;
    transform: translate(20%);
    position: relative !important;
  }

  .HEADER .frame-3 {
    position: relative;
    transform: translate(-20%);
  }
}

@media screen and (width <= 960px) {
  .HEADER .HEADER-CONTENT-wrapperItems {
    position: relative;
  }

  .HEADER .menu-icon {
    cursor: pointer;
    font-size: 1.8rem;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-50%, -50%);
  }

  .HEADER .tb {
    top: 0;
    left: 0;
    transform: translate(20%);
    position: relative !important;
  }

  .HEADER .design-component-instance-node-large, .HEADER .button-2-large {
    display: none;
  }

  .menu-container {
    display: inline-block;
    position: relative;
  }

  .menu-dropdown {
    z-index: 1;
    background-color: #fff;
    border-radius: 10%;
    min-width: 150px;
    display: flex;
    position: absolute;
    transform: translate(-30%, 70%);
    box-shadow: 0 8px 16px #0003;
  }

  .menu-container:hover .menu-dropdown {
    display: block;
  }

  .menu-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .menu-list li {
    padding: 8px 16px;
  }

  .menu-list li a {
    color: #333;
    text-decoration: none;
    display: block;
  }

  .menu-list li a:hover {
    background-color: #f0f0f0;
  }
}

.frame-29 {
  background-color: var(--primary-darkest);
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 32px 1%;
  display: flex;
  position: relative;
}

.footer {
  flex: none;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.comment-bottom {
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  gap: 10px;
  display: flex;
}

.capterra {
  justify-content: flex-end;
  width: 100%;
  min-width: 77px;
  max-width: 120px;
  display: flex;
}

.getapp {
  align-self: flex-start;
  width: 100%;
  min-width: 60px;
  max-width: 80px;
}

.getapp-img {
  width: 100%;
  height: 100%;
}

.tb-1-1 {
  width: 109.03px !important;
  height: 40.44px !important;
  position: relative !important;
}

.text-wrapper-15 {
  color: var(--gray-400);
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.frame-30 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 36px;
  display: inline-flex;
  position: relative;
}

.frame-31 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.text-wrapper-16 {
  color: var(--gray-400);
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
  width: 83px;
  margin-top: -1px;
  position: relative;
}

.text-wrapper-compare {
  color: var(--gray-400);
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
  width: 90px;
  margin-top: -1px;
  position: relative;
}

.text-wrapper-17 {
  color: var(--white);
  letter-spacing: 0;
  align-self: stretch;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: underline;
  position: relative;
}

@media screen and (width <= 1210px) {
  .footer {
    gap: 120px;
  }
}

@media screen and (width <= 1152px) {
  .footer {
    gap: 30px;
    padding: 0 2%;
  }
}

@media screen and (width <= 960px) {
  .footer {
    gap: 40px;
  }
}

@media screen and (width <= 860px) {
  .footer {
    flex-direction: column;
  }

  .text-wrapper-15 {
    text-align: center;
  }
}

.main-3 {
  background-color: #f5f7fc;
  flex-direction: column;
  gap: 32px;
  display: flex;
  position: relative;
}

.hero-section-2 {
  align-self: center;
  width: 100%;
  max-width: 1110px;
  padding-top: 6rem;
}

.middle-section-2 {
  border-radius: 1rem;
  flex-direction: column;
  align-self: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  max-width: 940px;
  display: flex;
}

.h2-class {
  color: var(--primary-darkest);
  text-align: center;
  padding: 1rem;
  font-family: Inter, sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.2;
}

.bottom-text-header {
  color: var(--primary-darkest);
  padding: 1rem;
  font-family: Inter, sans-serif;
  font-size: 38px;
  font-weight: 600;
  line-height: 43px;
}

.bottom-text {
  color: #000;
  padding: 1rem;
  font-family: Inter, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 26.25px;
}

.h3-class-3 {
  color: #000;
  text-align: center;
  padding: 1rem;
  font-family: Inter, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.2;
}

.center {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bottom {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.h1-class-alter {
  color: var(--primary-darkest);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
}

.center-bottom {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1050px;
  margin-top: 20px;
  display: flex;
}

.alternatives-section {
  flex-direction: column;
  align-self: center;
  width: 100%;
  max-width: 1000px;
  padding: 4rem;
  font-family: Inter, sans-serif;
  display: flex;
}

.altenatives {
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
  display: flex;
}

.alternative {
  flex-direction: column;
  flex: 1 1 0;
  justify-content: center;
  display: flex;
}

.alternative-1 {
  flex-direction: column;
  flex: 1 1 0;
  justify-content: flex-start;
  display: flex;
}

.alternative-3 {
  flex-direction: column;
  flex: 1 1 0;
  justify-content: flex-end;
  display: flex;
}

.alternative-link {
  color: var(--gray-800);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  text-decoration-line: underline;
  display: block;
}

.alternatives-text {
  flex-direction: column;
  gap: 18px;
  display: flex;
}

.h2-alternative {
  color: var(--primary-darkest);
  font-size: 38px;
  font-weight: 600;
  line-height: 1.2;
}

.primary-color {
  color: var(--primary);
}

.primary-color-2 {
  color: var(--primary);
  font-weight: 600;
}

.section {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 2.5rem;
  display: flex;
  position: relative;
}

.column-container {
  z-index: 10;
  flex-flow: wrap;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  max-width: 80rem;
  display: flex;
  position: relative;
}

.comparison-bottom-2 {
  background-color: #f2f2f9;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
  max-width: 1110px;
  margin-bottom: 48px;
  padding: 2.25rem;
  display: flex;
}

.bottom-text-part {
  text-align: left;
  flex-direction: column;
  flex: 2;
  display: flex;
}

.button-part {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (width <= 991px) {
  .hero-section, .section {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .column {
    width: 50%;
  }

  .article-img-wrapper {
    height: 52vw;
  }

  .article-columns {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }
}

@media screen and (width <= 900px) {
  .altenatives {
    flex-direction: column;
    align-self: center;
    margin-top: 10px;
  }
}

@media screen and (width <= 800px) {
  .h3-class-3 {
    font-size: 20px;
  }

  .h1-class-alter {
    font-size: 3rem;
  }
}

@media screen and (width <= 767px) {
  .section {
    padding: 3rem 1rem;
  }

  .container {
    flex-direction: column;
  }

  .column, .column.is-half {
    width: 100%;
  }
}

@media screen and (width <= 600px) {
  .comparison-bottom-2 {
    flex-direction: column;
    gap: 32px;
  }
}

@media screen and (width <= 500px) {
  .h3-class-3 {
    font-size: 18px;
  }

  .h1-class-alter {
    font-size: 2.5rem;
  }
}

.main-3 {
  background-color: #f5f7fc;
  flex-direction: column;
  gap: 32px;
  display: flex;
  position: relative;
}

.hero-section-comp {
  padding-top: 6rem;
}

.company-name-comp {
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
  max-width: 1110px;
  margin-top: 20px;
  font-size: 3rem;
  display: flex;
}

.middle-section-2 {
  border-radius: 1rem;
  flex-direction: column;
  align-self: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  max-width: 940px;
  display: flex;
}

.h2-class {
  color: var(--primary-darkest);
  text-align: center;
  padding: 1rem;
  font-family: Inter, sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.2;
}

.bottom-text-header {
  color: var(--primary-darkest);
  padding: 1rem;
  font-family: Inter, sans-serif;
  font-size: 38px;
  font-weight: 600;
  line-height: 43px;
}

.bottom-text {
  color: #000;
  padding: 1rem;
  font-family: Inter, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 26.25px;
}

.h3-class-2 {
  color: #000;
  text-align: center;
  padding: 1rem;
  font-family: Inter, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.2;
}

.center {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bottom {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.h1-class-comp {
  color: var(--primary-darkest);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
}

.center-bottom {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1050px;
  margin-top: 20px;
  display: flex;
}

.frame-feature {
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  gap: 64px;
  max-width: 1050px;
  margin: 48px 0;
  display: flex;
}

.feature-header {
  color: var(--primary-darkest);
  text-align: center;
  min-height: 56px;
  margin-top: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.75rem;
}

.feature-icon {
  width: 100px;
  height: 100px;
}

.feature-text {
  text-align: center;
  color: var(--gray-800);
  margin-top: 20px;
  font-family: Inter, sans-serif;
  line-height: 1.3rem;
}

.feature {
  flex-direction: column;
  align-items: center;
  width: 300px;
  min-height: 320px;
  font-family: Inter, sans-serif;
  display: flex;
}

.feature-top {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.primary-color {
  color: var(--primary);
}

.primary-color-2 {
  color: var(--primary);
  font-weight: 600;
}

.section {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 2.5rem;
  display: flex;
  position: relative;
}

.column-container {
  z-index: 10;
  flex-flow: wrap;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  max-width: 80rem;
  display: flex;
  position: relative;
}

.comparison-bottom {
  background-color: #f2f2f9;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
  max-width: 1110px;
  margin-bottom: 48px;
  padding: 2.25rem;
  display: flex;
}

.bottom-text-part {
  text-align: left;
  flex-direction: column;
  flex: 2;
  display: flex;
}

.button-part {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (width <= 1100px) {
  .frame-feature {
    gap: 32px;
  }
}

@media screen and (width <= 1020px) {
  .frame-feature {
    flex-direction: column;
  }

  .feature {
    width: 600px;
  }
}

@media screen and (width <= 991px) {
  .hero-section-comp, .section {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .column {
    width: 50%;
  }

  .article-img-wrapper {
    height: 52vw;
  }

  .article-columns {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }
}

@media screen and (width <= 800px) {
  .h3-class-2 {
    font-size: 20px;
  }

  .h1-class-comp {
    font-size: 3rem;
  }
}

@media screen and (width <= 767px) {
  .section {
    padding: 3rem 1rem;
  }

  .container {
    flex-direction: column;
  }

  .column, .column.is-half {
    width: 100%;
  }
}

@media screen and (width <= 700px) {
  .feature {
    max-width: 500px;
  }
}

@media screen and (width <= 600px) {
  .comparison-bottom {
    flex-direction: column;
    gap: 32px;
  }

  .h3-class-2 {
    font-size: 18px;
  }

  .h1-class-comp {
    font-size: 2.5rem;
  }

  .feature {
    max-width: 400px;
  }
}

@media screen and (width <= 500px) {
  .feature {
    max-width: 300px;
  }
}

.comparison-table {
  border-collapse: collapse;
  border: 1px solid #e5e7eb;
  align-self: center;
  width: 100%;
  max-width: 1110px;
  margin: 68px 0 20px;
  font-family: Inter, sans-serif;
  display: table;
  overflow: hidden;
}

.table-title {
  color: var(--primary-darkest);
  width: 100%;
  font-family: Inter, sans-serif;
  padding: 45px 60px !important;
  font-size: 25px !important;
}

.tick {
  color: #19b519;
  font-size: 23px !important;
}

.cross {
  color: #a01e1e;
  font-size: 23px !important;
}

.table-header {
  width: 100%;
  max-width: 1110px;
  font-weight: bold;
  display: flex;
}

.table-header > div {
  text-align: center;
  color: var(--primary-darkest);
  border: 1px solid #e5e7eb;
  flex: 1;
  width: 100%;
  max-width: 1110px;
  padding: 25px 10px;
  font-size: 20px;
}

.table-row {
  width: 100%;
  max-width: 1110px;
  display: flex;
}

.table-row > div {
  text-align: center;
  color: var(--gray-800);
  border: 1px solid #e5e7eb;
  flex: 1;
  width: 100%;
  max-width: 1110px;
  padding: 15px 10px;
  font-size: 17px;
  font-weight: 600;
}

.table-header-feature {
  width: 33.333% !important;
}

.pinkish-color-feature {
  background-color: #f5e9ffc4;
  width: 33.333% !important;
}

.greenish-color-feature {
  background-color: #e4fdf4c4;
  width: 33.333% !important;
}

.pinkish-color {
  background-color: #f5e9ffc4;
}

.greenish-color {
  background-color: #e4fdf4c4;
}

@media screen and (width <= 400px) {
  .table-row > div {
    font-size: 15px;
  }
}

.main-2 {
  background-color: #f5f7fc;
  flex-direction: column;
  gap: 48px;
  display: flex;
  position: relative;
}

.hero-section {
  padding-top: 6rem;
}

.middle-section {
  background-color: #f2f2f9;
  border-radius: 1rem;
  flex-direction: column;
  align-self: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  max-width: 80rem;
  padding: 4rem;
  display: flex;
}

.h2-class {
  color: var(--primary-darkest);
  text-align: center;
  padding: 1rem;
  font-family: Inter, sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.2;
}

.h3-class {
  color: #000;
  text-align: center;
  padding: 1rem;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
}

.center {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bottom {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.h1-class {
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 5rem;
  font-weight: 600;
  line-height: 1.2;
}

.primary-color {
  color: var(--primary);
}

.primary-color-2 {
  color: var(--primary);
  font-weight: 600;
}

.section-blog {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2.5rem;
  display: flex;
  position: relative;
}

.news-section {
  align-self: center;
  max-width: 1280px;
  margin: 0 2.5rem;
  display: flex;
  position: relative;
}

.news {
  perspective: 35rem;
  letter-spacing: .005rem;
  width: 100%;
  padding: 1rem;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.news-items:hover {
  transform: translate(0, -3px);
  box-shadow: 0 0 15px #0000004d;
}

.news-items {
  transform-style: preserve-3d;
  background-color: 1px solid var(--white);
  border-color: var(--white);
  box-shadow: var(--double-shadow);
  border-radius: 16px;
  align-items: stretch;
  width: 100%;
  max-width: 100%;
  height: 100%;
  text-decoration: none;
  display: flex;
  overflow: hidden;
  transform: translate3d(0, 0, 0)scale3d(1, 1, 1)rotateX(0)rotateY(0)rotateZ(0)skew(0deg);
}

.news-image-wrapper {
  width: 50%;
  height: 36vw;
  min-height: 100%;
  max-height: 26rem;
  position: relative;
  overflow: hidden;
}

.news-image {
  object-fit: cover;
  vertical-align: middle;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.news-text {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 2rem;
  display: flex;
  position: relative;
}

.news-date {
  color: var(--gray-800);
  letter-spacing: .01rem;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1.25rem;
  font-size: .83rem;
  font-weight: 500;
  display: flex;
}

.news-content {
  flex: 1;
}

.news-title {
  color: var(--primary-darkest);
  text-align: left;
  margin-bottom: 1.3rem;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.1;
}

.news-desc {
  color: var(--gray-800);
  margin-bottom: 2rem;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.news-bottom {
  grid-column-gap: 0px;
  color: var(--gray-800);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
  text-decoration: none;
  display: flex;
}

.column-container {
  z-index: 10;
  flex-flow: wrap;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  max-width: 80rem;
  display: flex;
  position: relative;
}

.company-name {
  margin-top: 20px;
  font-size: 3rem;
}

.article-columns {
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  display: flex;
}

.column {
  justify-content: center;
  width: 33.3333%;
  padding: 1rem;
  display: flex;
}

.column-wrap {
  width: 100%;
}

.column h2 {
  margin-bottom: 15px;
}

.article-item {
  perspective: 35rem;
  background-color: var(--white);
  border-color: var(--white);
  box-shadow: var(--double-shadow);
  transform-origin: center;
  border-radius: 16px;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: center;
  width: 100%;
  text-decoration: none;
  transition: transform 10ms;
  display: flex;
  position: relative;
  overflow: hidden;
}

.article-contents {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.article-item:hover {
  transform: translate(0, -3px);
  box-shadow: 0 0 15px #0000004d;
}

.article-img-wrapper {
  object-fit: cover;
  width: 100%;
  height: 16vw;
  max-height: 14rem;
  position: relative;
  overflow: hidden;
}

.article-img {
  object-fit: cover;
  border-radius: 16px 16px 0 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.card-content {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 1.5rem;
  font-family: Inter, sans-serif;
  display: flex;
  position: relative;
}

.date {
  color: var(--gray-800);
  letter-spacing: .01rem;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1.25rem;
  font-size: .83rem;
  font-weight: 500;
  display: flex;
}

.title {
  box-sizing: border-box;
  flex: 1;
  margin: .5rem;
  display: block;
}

.title-text {
  color: var(--primary-darkest);
  font-weight: var(--h-1-font-weight);
  min-height: 52px;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.35;
  text-decoration: none;
}

.card-bottom {
  grid-column-gap: 0px;
  color: var(--gray-800);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
  text-decoration: none;
  display: flex;
}

.author-2 {
  flex-direction: row;
  align-items: center;
  gap: 5px;
  max-width: 100%;
  font-weight: 600;
  display: flex;
}

.author-img {
  border-radius: 50%;
  width: 2.25rem;
  height: 2.25rem;
  overflow: hidden;
}

.image {
  width: 36px;
  height: auto;
}

.author-name {
  font-family: Inter, sans-serif;
  font-size: .83rem;
  line-height: 1.5;
}

.tag {
  grid-column-gap: .375rem;
  color: #344054;
  white-space: nowrap;
  text-transform: none;
  background-color: #f2f4f7;
  border-radius: 10rem;
  align-items: center;
  padding: .25rem .75rem;
  font-size: .83rem;
  font-weight: 500;
  display: flex;
  position: static;
}

.divider {
  color: #eaecf0;
  -webkit-text-stroke-color: #eaecf0;
  background-color: #eaecf0;
  width: 100%;
  height: 1px;
  margin-left: .75rem;
  margin-right: .75rem;
}

.read-time {
  display: flex;
}

.min-read-txt {
  margin-left: .35rem;
}

@media screen and (width <= 1272px) {
  .news-image-wrapper {
    width: 60%;
  }
}

@media screen and (width <= 1100px) {
  .news-items {
    flex-direction: column;
  }

  .news-image-wrapper {
    width: 100%;
    min-height: auto;
  }
}

@media screen and (width <= 991px) {
  .hero-section, .section {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .column {
    width: 50%;
  }

  .article-img-wrapper, .news-image-wrapper {
    height: 52vw;
  }

  .article-columns {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }
}

@media screen and (width <= 767px) {
  .section {
    padding: 3rem 1rem;
  }

  .container {
    flex-direction: column;
  }

  .column, .column.is-half {
    width: 100%;
  }
}

@media screen and (width <= 700px) {
  .news-title {
    font-size: 2rem;
  }
}

@media screen and (width <= 550px) {
  .news-title {
    font-size: 1.75rem;
  }
}

@media screen and (width <= 450px) {
  .news-title {
    font-size: 1.5rem;
  }
}

.bottom-section {
  background-color: #f2f2f9;
  border-radius: 1rem;
  flex-direction: column;
  align-self: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  max-width: 720px;
  margin-bottom: 96px;
  padding: 4rem;
  display: flex;
}

.thank-you-message {
  color: var(--primary-darkest);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.2;
}

.h1-class-3 {
  color: var(--primary-darkest);
  font-family: Inter, sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.2;
}

.paragraph-divider {
  background: #736edb;
  background: -moz-linear-gradient(90deg, var(--primary-darkest) 0, var(--primary-lightest) 100%);
  background: -webkit-linear-gradient(90deg, var(--primary-darkest), var(--primary-lightest));
  background: linear-gradient(90deg, var(--primary-darkest), var(--primary-lightest));
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#736edb", endColorstr= "#e84fd9", GradientType= 1);
  border-radius: 2px;
  width: 50px;
  height: 4px;
  margin-bottom: 10px;
  display: inline-block;
}

.h2-class-2 {
  color: #000;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.2;
}

.subscribe-button {
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 56px;
  flex: none !important;
}

.form-style {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1.5rem 0 0;
  display: flex;
}

.form-input-style {
  color: #000;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  width: 100%;
  height: 56px;
  padding: 10px;
}

@media screen and (width <= 540px) {
  .bottom-section {
    padding: 2rem;
  }

  .subscribe-button {
    height: 40px;
    font-size: 13px;
  }

  .form-input-style {
    height: 40px;
  }
}

.article-item {
  perspective: 35rem;
  background-color: var(--white);
  border-color: var(--white);
  box-shadow: var(--double-shadow);
  transform-origin: center;
  border-radius: 16px;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: center;
  width: 100%;
  text-decoration: none;
  transition: transform 10ms;
  display: flex;
  position: relative;
  overflow: hidden;
}

.article-item:hover {
  transform: translate(0, -3px);
  box-shadow: 0 0 15px #0000004d;
}

.article-contents {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.article-img-wrapper {
  object-fit: cover;
  width: 100%;
  height: 16vw;
  max-height: 14rem;
  position: relative;
  overflow: hidden;
}

.article-img {
  object-fit: cover;
  border-radius: 16px 16px 0 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.card-content {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 1.5rem;
  font-family: Inter, sans-serif;
  display: flex;
  position: relative;
}

.date {
  color: var(--gray-800);
  letter-spacing: .01rem;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1.25rem;
  font-size: .83rem;
  font-weight: 500;
  display: flex;
}

.divider {
  color: #eaecf0;
  -webkit-text-stroke-color: #eaecf0;
  background-color: #eaecf0;
  width: 100%;
  height: 1px;
  margin-left: .75rem;
  margin-right: .75rem;
}

.read-time {
  display: flex;
}

.min-read-txt {
  margin-left: .35rem;
}

.title {
  box-sizing: border-box;
  flex: 1;
  min-height: 120px;
  margin: .5rem;
  display: block;
}

.title-text {
  color: var(--primary-darkest);
  font-weight: var(--h-1-font-weight);
  min-height: 52px;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.35;
  text-decoration: none;
}

.card-bottom {
  grid-column-gap: 0px;
  color: var(--gray-800);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
  text-decoration: none;
  display: flex;
}

.author-2 {
  flex-direction: row;
  align-items: center;
  gap: 5px;
  max-width: 100%;
  font-weight: 600;
  display: flex;
}

.author-img {
  border-radius: 50%;
  width: 2.25rem;
  height: 2.25rem;
  overflow: hidden;
}

.image {
  width: 36px;
  height: auto;
}

.author-name {
  font-family: Inter, sans-serif;
  font-size: .83rem;
  line-height: 1.5;
}

.tag {
  grid-column-gap: .375rem;
  color: #344054;
  white-space: nowrap;
  text-transform: none;
  background-color: #f2f4f7;
  border-radius: 10rem;
  align-items: center;
  padding: .25rem .75rem;
  font-size: .83rem;
  font-weight: 500;
  display: flex;
  position: static;
}

@media screen and (width <= 991px) {
  .article-img-wrapper {
    height: 52vw;
  }
}

h1 {
  color: #333;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: bold;
}

h2 {
  color: #555;
  margin-top: 20px;
  font-size: 27px;
  font-weight: bold;
}

h3 {
  color: #555;
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: bolder;
}

h4 {
  color: #555;
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

ul, ol {
  padding-left: 20px;
  list-style-type: auto;
}

ul li {
  margin-bottom: 5px;
}

ol li {
  margin-bottom: 10px;
}

.main {
  background-color: #f5f7fc;
  flex-direction: column;
  display: flex;
  position: relative;
}

.hero-section-2 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  display: flex;
  position: relative;
}

.section-2 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 2.5rem;
  display: flex;
  position: relative;
}

.starter-container {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  width: 100%;
  max-width: 64rem;
  display: flex;
}

.label-2 {
  background-color: var(--primary-lightest);
  color: var(--primary-darker);
  z-index: 12;
  border: 1px none var(--primary-lighter);
  border-radius: .3rem;
  margin-left: 0;
  margin-right: 0;
  padding: .25rem .75rem;
  font-family: Inter, sans-serif;
  font-size: .83rem;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  inset: 0% auto auto 0%;
}

.middle-starter {
  text-align: center;
  color: #475467;
  letter-spacing: .005rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 2rem;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.author {
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 100%;
  font-weight: 600;
  display: flex;
}

.author-img2 {
  border-radius: 50%;
  width: 2.25rem;
  height: 2.25rem;
  overflow: hidden;
}

.image {
  width: 36px;
  height: auto;
}

.author-name {
  font-family: Inter, sans-serif;
  font-size: .83rem;
  line-height: 1.5;
}

.divider-section-2 {
  z-index: 10;
  flex-flow: wrap;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  max-width: 720px;
  padding: 2rem;
  display: flex;
  position: relative;
}

.date-read-section {
  color: var(--gray-800);
  letter-spacing: .01rem;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1.25rem;
  font-family: Inter, sans-serif;
  font-size: .83rem;
  font-weight: 500;
  display: flex;
}

.content-link {
  color: #007bff;
  text-decoration: none;
}

.date-divider {
  color: #eaecf0;
  -webkit-text-stroke-color: #eaecf0;
  background-color: #eaecf0;
  width: 100%;
  height: 2px;
  margin-left: .75rem;
  margin-right: .75rem;
}

.read-time-divider, .read-time {
  display: flex;
}

.min-read-txt {
  margin-left: .35rem;
}

.content-text {
  display: flex;
}

.h1-class-2 {
  color: var(--primary-darkest);
  font-family: Inter, sans-serif;
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.2;
}

.content-section {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 2.5rem 5rem;
  display: flex;
  position: relative;
}

.content-container {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-flow: wrap;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  max-width: 64rem;
  margin-left: 0;
  display: flex;
}

.content {
  color: #475467;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  max-width: 840px;
  font-family: Inter, sans-serif;
  font-size: 1.1rem;
  font-weight: 410;
  line-height: 1.75;
  display: flex;
}

.content-img {
  border-radius: 3%;
  align-self: center;
  width: 100%;
  max-width: 720px;
}

@media screen and (width <= 991px) {
  .hero-section-2, .section-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media screen and (width <= 767px) {
  .h1-class-2 {
    font-size: 3.25rem;
  }

  .section-2 {
    padding: 3rem 0;
  }
}

@media screen and (width <= 495px) {
  .h1-class-2 {
    font-size: 3rem;
  }

  .middle-starter {
    margin: 2rem 0;
  }

  .divider-section-2 {
    padding: 2rem 0;
  }

  .content {
    font-size: 1rem;
  }
}

.feature-main {
  background-color: var(--background-blue);
  flex-direction: column;
  display: flex;
}

.introduction-container {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 0;
  font-family: Inter, sans-serif;
  display: flex;
  background: var(--primary-lightest) !important;
}

.feature-container {
  justify-content: center;
  align-self: stretch;
  width: 100%;
  margin: 0 auto 68px;
  font-family: Inter, sans-serif;
}

.double-side-intro {
  flex-direction: row;
  justify-content: space-between;
  gap: 72px;
  width: 100%;
  max-width: 1272px;
  padding: 0 120px;
  display: flex;
}

.left-intro {
  flex: 1 1 0;
  margin: auto;
  display: grid;
}

.text-intro {
  letter-spacing: -.4px;
  text-align: left;
  color: var(--primary-darkest);
  width: 100%;
  font-size: 48px;
  font-weight: 600;
  line-height: 61px;
}

.intro-body {
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26.25px;
}

.right-intro {
  z-index: 1;
  flex: 1 1 0;
  justify-content: right;
  align-items: center;
  padding-left: 16px;
  display: flex;
  position: relative;
}

.right-img {
  vertical-align: middle;
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 400px;
}

.features {
  align-self: center;
  width: 100%;
  max-width: 1220px;
  margin-top: 50px;
  font-family: Inter, sans-serif;
  font-size: 1.4rem;
}

.general-feature-title {
  text-align: center;
  color: var(--primary-darkest);
  width: 100%;
  margin: 0 8%;
  padding: 4rem 0 2rem;
  font-family: Inter, sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 2.75rem;
}

.feature-intro {
  text-align: left;
  width: 100%;
  padding: 0 10%;
  font-size: 18px;
  font-weight: 500;
  line-height: 26.25px;
}

.feature-list {
  flex-direction: row;
  margin: 0 8% 40px;
  display: flex;
  position: relative;
}

.feature-list-left {
  flex: 1;
  padding-right: 40px;
}

.feature-list-left-subheading {
  color: #05070a;
  letter-spacing: -.44px;
  width: 100%;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
  line-height: 4.6rem;
}

.feature-list-left-text {
  color: #4d4d4d;
  width: 78%;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 26.25px;
}

.feature-list-left-text-960 {
  color: #4d4d4d;
  width: 78%;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 26.25px;
  display: none;
}

.feature-list-right-image-wrapper {
  box-sizing: inherit;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: auto;
  max-width: 150px;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}

.feature-list-img {
  vertical-align: middle;
  max-width: 100%;
  height: 172px;
}

.feature-list-left-image-wrapper {
  box-sizing: inherit;
  box-shadow: var(--double-shadow);
  background-color: #fff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: auto;
  max-width: 400px;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 3rem;
  padding: 1rem;
  display: flex;
}

.feature-list-right {
  flex: 1;
  padding-left: 100px;
}

.feature-list-right-text {
  color: #4d4d4d;
  width: 100%;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 26.25px;
}

.killer-feature {
  color: var(--white);
  border: 1px solid var(--gray-400);
  background: linear-gradient(90deg, #4778b8bc, #755eb0);
  flex-direction: column;
  align-items: center;
  padding: 5rem;
  font-family: Inter, sans-serif;
  display: flex;
  box-shadow: 0 4px 8px #0000001a;
}

.h1-killer {
  color: var(--primary-darkest);
  text-align: center;
  margin-left: 15%;
  margin-right: 15%;
  font-size: 44px;
  line-height: 3.5rem;
}

.killer-duo {
  flex-direction: column;
  max-width: 100%;
  margin: 0 75px;
  display: flex;
}

.killer-content {
  margin-bottom: 18px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.75rem;
}

.killer-feature-img {
  border-radius: 10px;
  align-self: center;
  width: 500px;
  max-width: 100%;
  height: -moz-fit-content;
  height: fit-content;
}

.killer-feature-img-1152 {
  display: none;
}

.frame-19 {
  background: linear-gradient(102.3deg, #684db0 0%, #258 100%);
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  width: 100%;
  padding: 72px 0;
  display: flex;
  position: relative;
}

.frame-20 {
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame-21 {
  flex: none;
  align-items: center;
  gap: 64px;
  max-width: 1152px;
  padding: 0 64px;
  display: flex;
  position: relative;
}

.text-wrapper-10 {
  color: var(--white);
  font-family: var(--h-2-font-family);
  font-size: var(--h-2-font-size);
  font-style: var(--h-2-font-style);
  font-weight: var(--h-2-font-weight);
  letter-spacing: var(--h-2-letter-spacing);
  line-height: var(--h-2-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.text-wrapper-11 {
  color: var(--white);
  font-family: var(--subtitle-font-family);
  font-size: var(--subtitle-font-size);
  font-style: var(--subtitle-font-style);
  font-weight: var(--subtitle-font-weight);
  letter-spacing: var(--subtitle-letter-spacing);
  line-height: var(--subtitle-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.CARD-5 {
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--white);
  box-shadow: var(--double-shadow);
  transform-origin: center;
  border-radius: 16px;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: center;
  gap: 48px;
  padding: 32px;
  transition: transform 10ms;
  display: flex;
  position: relative;
}

.frame-10 {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  min-height: 198px;
  display: flex;
  position: relative;
}

.frame-32 {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  gap: 48px;
  width: 100%;
  display: flex;
  position: relative;
}

.starter {
  color: var(--primary-darkest);
  font-family: var(--h-4-font-family);
  font-size: var(--h-4-font-size);
  font-style: var(--h-4-font-style);
  font-weight: var(--h-4-font-weight);
  letter-spacing: var(--h-4-letter-spacing);
  line-height: var(--h-4-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.icon-instance-node {
  width: 128px !important;
  height: 128px !important;
  position: relative !important;
}

.automate-mobile-and-3 {
  color: #0000;
  letter-spacing: 0;
  align-self: stretch;
  min-height: 144px;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.text-wrapper-12 {
  color: #258;
  font-weight: 600;
}

.text-wrapper-13 {
  color: #5f6b77;
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
}

.frame-8-feature {
  background-color: var(--background-blue);
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  width: 100%;
  padding: 72px 0;
  display: flex;
  position: relative;
}

.text-wrapper-5 {
  color: var(--primary-darkest);
  font-family: var(--h-2-font-family);
  font-size: var(--h-2-font-size);
  font-style: var(--h-2-font-style);
  font-weight: var(--h-2-font-weight);
  letter-spacing: var(--h-2-letter-spacing);
  line-height: var(--h-2-line-height);
  text-align: center;
  align-self: stretch;
  margin: 2%;
  position: relative;
}

.frame-9 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 32px;
  max-width: 1152px;
  margin: auto;
  display: flex;
}

.CARD-2-copy-feature {
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--white);
  box-shadow: var(--double-shadow);
  transform-origin: center;
  border-radius: 16px;
  flex-direction: column;
  flex-grow: 1;
  align-self: center;
  align-items: center;
  width: 400px;
  min-width: 325px;
  height: 448px;
  padding: 32px;
  transition: transform 10ms;
  display: flex;
  position: relative;
}

.frame-32-copy {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.automate-mobile-and-2 {
  color: var(--gray-800);
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
  text-align: center;
  align-self: stretch;
  min-height: 144px;
  position: relative;
}

@media screen and (width <= 1440px) {
  .killer-duo {
    margin: 0 36px;
  }
}

@media screen and (width <= 1220px) {
  .killer-duo {
    margin: 0;
  }

  .feature-list-right {
    padding-left: 100px;
  }

  .frame-9 {
    flex-direction: column;
    gap: 48px;
  }

  .CARD-2-copy-feature {
    width: 50%;
  }

  .feature-list {
    margin: 0 5% 36px;
  }

  .feature-list-img {
    height: 118px;
  }
}

@media screen and (width <= 1152px) {
  .frame-21 {
    gap: 48px;
  }

  .killer-feature-img-1152 {
    border-radius: 10px;
    align-self: center;
    width: 500px;
    max-width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 40px;
    display: flex;
  }

  .killer-duo {
    flex-direction: column;
  }
}

@media screen and (width <= 1140px) {
  .frame-21 {
    gap: 36px;
  }
}

@media screen and (width <= 1120px) {
  .frame-21 {
    gap: 24px;
  }
}

@media screen and (width <= 1100px) {
  .frame-21 {
    flex-direction: column;
  }
}

@media screen and (width <= 1080px) {
  .feature-list-left-subheading {
    line-height: 2.5rem;
  }

  .killer-content {
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media screen and (width <= 960px) {
  .feature-list {
    flex-direction: column;
    align-items: center;
    margin: 0 5%;
  }

  .double-side-intro {
    padding: 0 56px;
  }

  .feature-list-left, .feature-list-right {
    text-align: center;
    order: 1;
    padding: 0 1rem;
  }

  .feature-list-left-subheading, .feature-list-right-subheading {
    order: 1;
    margin-bottom: 20px;
  }

  .feature-list-left-image-wrapper, .feature-list-right-image-wrapper {
    order: 2;
    width: 80%;
    max-width: 350px;
    margin: 20px 0;
  }

  .feature-list-left-text-960 {
    order: 3;
    width: 90%;
    margin-top: 16px;
    display: flex;
  }

  .feature-list-left-text, .feature-list-right-text {
    display: none;
  }

  .general-feature-title {
    font-size: 40px;
  }

  .feature-list-img {
    max-width: 100%;
    height: 100px;
  }
}

@media screen and (width <= 860px) {
  .double-side-intro {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 72px;
    padding: 0 72px;
  }

  .text-intro {
    text-align: center;
  }

  .feature-link {
    justify-content: center;
    display: flex;
  }

  .general-feature-title {
    font-size: 36px;
  }

  .killer-content {
    margin-left: 0;
    margin-right: 0;
  }

  .frame-21 {
    padding: 0 8%;
  }
}

@media screen and (width <= 700px) {
  .CARD-2-copy-feature {
    width: 60%;
  }
}

@media screen and (width <= 660px) {
  .killer-feature {
    padding: 3rem;
  }

  .h1-killer {
    margin-left: 0;
    margin-right: 0;
    font-size: 36px;
  }

  .killer-content {
    font-size: 20px;
  }
}

@media screen and (width <= 600px) {
  .double-side-intro {
    padding: 0 36px;
  }
}

.input-custom {
  background-color: var(--white);
  border: 1px solid;
  border-radius: 5px;
  align-items: flex-end;
  gap: 10px;
  padding: 8px 10px;
  display: inline-flex;
  position: relative;
}

.input-custom::placeholder, .input-custom .PLACEHOLDER {
  color: var(--gray-400);
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.input-custom.default {
  border-color: var(--primary-lightest);
}

.input-custom:hover {
  border-color: var(--primary-darker);
}

.input-custom.variant-2 {
  border-color: var(--primary);
}

.input-label {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 277px;
  display: flex;
  position: relative;
}

.input-label .label {
  color: var(--gray-800);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}

.frame-22 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 64px;
  width: 100%;
  display: flex;
  position: relative;
}

.go-back-button {
  display: none;
}

.logo-style {
  width: 109.03px !important;
  margin-top: -.21px !important;
  margin-bottom: -.23px !important;
  position: relative !important;
}

.frame-23 {
  background-color: var(--background-blue);
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: center;
  align-items: center;
  gap: 32px;
  padding: 36px 72px 72px;
  display: flex;
  position: relative;
}

.primary-color-form {
  color: var(--primary-darker);
}

.frame-24 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.text-wrapper-6 {
  color: var(--primary-darkest);
  font-family: var(--h-2-font-family);
  font-size: var(--h-2-font-size);
  font-style: var(--h-2-font-style);
  font-weight: var(--h-2-font-weight);
  letter-spacing: var(--h-2-letter-spacing);
  line-height: var(--h-2-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.frame-12-form {
  background-color: #f5f7fc;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  max-width: 50%;
  height: 100%;
  padding-top: 72px;
  display: flex;
  position: relative;
}

.frame-25 {
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-end;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame-28 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.input-label-wrapper {
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  display: flex;
  position: relative;
}

.design-component-instance-node-2 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.button-submit {
  cursor: pointer;
  align-self: center;
  width: 100%;
  flex: none !important;
}

.h1-form {
  color: var(--primary-darkest);
  align-self: center;
  margin-bottom: 24px;
  font-family: Inter, sans-serif;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.2;
}

.form-footer-has-text-centered-mt-10 {
  align-self: center;
  width: 100%;
  display: flex;
}

.input-label-2 {
  flex: 1 !important;
  align-self: stretch !important;
  align-items: flex-start !important;
  width: 100% !important;
  height: 100px !important;
  display: flex !important;
}

.design-component-instance-node-3 {
  width: unset !important;
  flex: 1 !important;
  align-self: stretch !important;
}

.centered-form-container {
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

.popup {
  background: #00000080;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  bottom: 0;
  left: 0;
}

.popup-content {
  text-align: center;
  color: #fff;
  background: #549b40cb;
  border-radius: 8px;
  margin-top: auto;
  font-family: Poppins, Helvetica, sans-serif;
}

.text-wrapper-14-form {
  color: var(--primary-darkest);
  font-family: var(--subtitle-font-family);
  font-size: var(--subtitle-font-size);
  font-style: var(--subtitle-font-style);
  font-weight: var(--subtitle-font-weight);
  letter-spacing: var(--subtitle-letter-spacing);
  line-height: var(--subtitle-line-height);
  align-self: center;
  position: relative;
}

.input-label-4 {
  width: unset !important;
  flex: 1 !important;
}

@media screen and (width <= 1210px) {
  .text-wrapper-6 {
    text-align: center;
    font-size: var(--h-1-font-size);
    justify-content: center;
    align-self: center;
  }
}

@media screen and (width <= 1200px) {
  .frame-23 {
    padding: 36px 36px 72px;
  }
}

@media screen and (width <= 960px) {
  .frame-28 {
    flex-direction: column;
  }

  .input-label-4 {
    width: 100% !important;
  }
}

@media screen and (width <= 860px) {
  .frame-22 {
    width: 100%;
    padding: 0% 5%;
    max-width: 750px !important;
  }
}

@media screen and (width <= 700px) {
  .frame-12-form {
    width: 100%;
    max-width: 100%;
  }

  .go-back-button {
    width: -moz-fit-content;
    width: fit-content;
    padding: 20px 0 0 20px;
    display: block;
  }
}

.main-demo {
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  min-height: 860px;
  display: flex;
  position: relative;
}

.demo-double-section {
  flex-direction: row;
  width: 100%;
  height: 100%;
  display: flex;
}

.demo-first-section {
  background-color: var(--white);
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  width: 100%;
  max-width: 50%;
  padding: 20px 0 60px 30px;
  display: flex;
}

.demo-first-inner-section {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.column-view-form {
  display: none;
}

.h1-demo {
  color: var(--primary-darkest);
  align-self: center;
  margin-bottom: 24px;
  padding: 0 42px;
  font-family: Inter, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.2;
}

.GoogleReCaptchaProvider {
  display: none;
}

.frame-5 {
  background-color: var(--white);
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 24px 10px 48px;
  display: flex;
  position: relative;
}

.frame-8 {
  background-color: var(--white);
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  width: 100%;
  padding: 72px 0;
  display: flex;
  position: relative;
}

.frame-6-demo {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: inline-flex;
  position: relative;
}

.companies-demo {
  filter: grayscale();
  max-width: 100%;
  transition: filter .3s;
}

.companies-demo:hover {
  filter: none;
}

.text-wrapper-4 {
  color: var(--primary-darkest);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.img-wrapper2-demo {
  width: 200px;
  position: relative;
}

.frame-7-demo {
  flex-direction: row;
  flex: none;
  justify-content: center;
  width: 100%;
  display: flex;
  position: relative;
}

.frame-17 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame-18 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 64px;
  max-width: 1152px;
  padding: 0 64px;
  display: flex;
  position: relative;
}

.home-style {
  letter-spacing: 0;
  white-space: nowrap;
  color: var(--gray-800);
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}

.home-style-2 {
  letter-spacing: 0;
  white-space: nowrap;
  color: var(--gray-800);
  width: 100%;
  margin-top: -1px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}

.home-style:hover {
  color: var(--primary);
}

.text-wrapper-5 {
  color: var(--primary-darkest);
  font-family: var(--h-2-font-family);
  font-size: var(--h-2-font-size);
  font-style: var(--h-2-font-style);
  font-weight: var(--h-2-font-weight);
  letter-spacing: var(--h-2-letter-spacing);
  line-height: var(--h-2-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.primary-color-demo {
  color: var(--primary-darker);
}

.property-1-male {
  width: 80px !important;
  height: 80px !important;
  position: relative !important;
}

.capterra-2 {
  align-self: flex-end;
  width: 100%;
  min-width: 77px;
  max-width: 120px;
  display: flex;
}

.capterra-2 img {
  filter: grayscale();
}

.capterra-2 img:hover {
  filter: none;
}

.getapp-2 {
  align-self: flex-start;
  width: 100%;
  min-width: 60px;
  max-width: 80px;
}

.getapp-2 img {
  filter: grayscale();
}

.getapp-2 img:hover {
  filter: none;
}

.getapp-img-2 {
  width: 100%;
}

.quote-wrapper {
  flex-direction: row;
  gap: 64px;
  display: flex;
}

.quote-instance {
  flex: 1;
  border: 1px solid !important;
  border-color: var(--gray-400) !important;
  align-self: stretch !important;
  max-width: 480px !important;
}

.quote-2 {
  font-family: var(--bodycopy-large-font-family) !important;
  font-size: var(--bodycopy-large-font-size) !important;
  font-style: var(--bodycopy-large-font-style) !important;
  font-weight: var(--bodycopy-large-font-weight) !important;
  letter-spacing: var(--bodycopy-large-letter-spacing) !important;
  line-height: var(--bodycopy-large-line-height) !important;
}

.text-wrapper-7 {
  color: var(--primary-darkest);
  font-family: var(--subtitle-font-family);
  font-size: var(--subtitle-font-size);
  font-style: var(--subtitle-font-style);
  font-weight: var(--subtitle-font-weight);
  letter-spacing: var(--subtitle-letter-spacing);
  line-height: var(--subtitle-line-height);
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

@media screen and (width <= 1100px) {
  .frame-18 {
    gap: 36px;
  }
}

@media screen and (width <= 1050px) {
  .frame-18, .quote-wrapper {
    gap: 24px;
  }
}

@media screen and (width <= 1040px) {
  .quote-wrapper, .frame-18 {
    flex-direction: column;
  }

  .quote-instance {
    max-width: 480px !important;
  }
}

@media screen and (width <= 960px) {
  .demo-double-section {
    height: -moz-fit-content;
    height: fit-content;
  }
}

@media screen and (width <= 860px) {
  .frame-18 {
    padding: 0 8%;
  }

  .frame-8 {
    padding: 72px 5%;
  }

  .text-wrapper-4 {
    font-size: 25px;
  }

  .companies-demo {
    max-width: 100%;
  }
}

@media screen and (width <= 700px) {
  .demo-double-section {
    flex-direction: column;
  }

  .demo-first-section {
    justify-content: center;
    width: 100%;
    max-width: 100%;
  }

  .h1-demo {
    padding-top: 60px;
  }

  .home-style {
    display: none;
  }
}

.main-not-found-page {
  background-color: var(--background-blue);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100vh;
  font-family: Inter, sans-serif;
  display: flex;
}

.h1-not-found {
  font-size: 40px;
}

.p-not-found {
  font-size: 22px;
}

.go-back-home {
  color: var(--gray-800);
  text-decoration: none;
}

.thank-main {
  background-color: #f5f7fc;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  display: flex;
  position: relative;
}

.frame-wrapper-thank {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  width: 100%;
  height: 100%;
  padding: 96px 0;
  display: flex;
  position: relative;
}

.frame-4-thank {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.p-thank {
  color: var(--gray-800);
  font-family: var(--intro-font-family);
  font-size: var(--intro-font-size);
  font-style: var(--intro-font-style);
  font-weight: var(--intro-font-weight);
  letter-spacing: var(--intro-letter-spacing);
  line-height: var(--intro-line-height);
  max-width: 451px;
  position: relative;
}

.div-2-thank {
  flex: none;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  max-width: 1152px;
  display: flex;
  position: relative;
}

.div-wrapper-thank {
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
  align-items: flex-start;
  gap: 10px;
  padding: 0 64px;
  display: flex;
  position: relative;
}

.text-wrapper-3-thank {
  color: var(--primary-darkest);
  font-family: var(--h-1-font-family);
  font-size: 52px;
  font-style: var(--h-1-font-style);
  font-weight: var(--h-1-font-weight);
  letter-spacing: var(--h-1-letter-spacing);
  line-height: var(--h-1-line-height);
  margin-top: -1px;
  position: relative;
}

.img-wrapper-thank {
  justify-content: center;
  align-items: center;
  max-width: 473px;
  max-height: 355px;
  display: flex;
  position: relative;
}

.worker-thank {
  max-width: 100%;
  padding-right: 1%;
}

@media screen and (width <= 960px) {
  .div-wrapper-thank {
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 5%;
    display: flex;
    position: relative;
  }
}

@media screen and (width <= 860px) {
  .thank-main {
    height: 100%;
  }

  .div-2-thank {
    flex-direction: column;
    flex: none;
    justify-content: space-between;
    align-items: center;
    max-width: 1152px;
    display: flex;
    position: relative;
  }

  .div-wrapper-thank {
    text-align: center;
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5%;
    display: flex;
    position: relative;
  }

  .worker-thank {
    align-items: center;
    max-width: 80%;
    margin: 0 0 0 10px;
    padding-right: 0;
  }

  .frame-4-thank {
    align-items: center;
  }
}

.iframe-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.iframe-container iframe {
  color-scheme: light;
  border: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.arcade-container {
  width: 100%;
  height: 80vh;
  margin: 20px 0;
  position: relative;
}

.arcade-collection {
  color-scheme: light;
  border: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width <= 768px) {
  .arcade-container {
    height: 60vh;
  }
}

@media (width <= 480px) {
  .arcade-container {
    height: 50vh;
  }
}

/*# sourceMappingURL=index.e30bf503.css.map */
