.feature-main{
   
    display: flex;
    flex-direction: column;
    background-color: var(--background-blue);
    
}

.introduction-container{
    background: var(--primary-lightest) !important;
    width: 100%;
    /* max-width: 1220px; */
    margin: 0 auto;
    align-self: stretch;
    justify-content: center;
    font-family: Inter, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px 0;
    flex-direction: column;
    gap: 32px;
}

.feature-container{
    
    width: 100%;
    /* max-width: 1220px; */
    margin: 0 auto;
    align-self: stretch;
    justify-content: center;
    font-family: Inter, sans-serif;
    margin-bottom: 68px;
}

.double-side-intro{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1272px;
    width: 100%;
    padding: 0 120px;
    gap: 72px;
}

.left-intro{
    /*padding-right: 5rem;*/
    margin: auto;
    /*gap: 20px;*/
    display: grid;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
}

.text-intro{
    font-weight: 600;
    line-height: 61px;
    letter-spacing: -0.4px;
    width: 100%;
    text-align: left;
    font-size: 48px;
   
    color: var(--primary-darkest);
    /*margin: auto;*/
}

.intro-body{
    line-height: 26.25px;
    font-weight: 500;
    font-size: 18px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.right-intro{
    position: relative;
    display: flex;
    justify-content: right;
    z-index: 1;
    padding-left: 16px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    align-items: center;
}

.right-img{
    max-width: 100%;
    vertical-align: middle;
    border-radius: 10px;
    /*max-width: 400px;*/
    width: fit-content;
    max-height: 400px;
    height: fit-content;
}


.features{
    /*padding-bottom: 7rem ;*/
    font-family: Inter, sans-serif;
    margin-top: 50px;
    font-size: 1.4rem;
    max-width: 1220px;
    width: 100%;
    align-self: center;
}

.general-feature-title{
    font-weight: 600;
    font-size: 30px;
    line-height: 2.75rem;
    text-align: center;
    padding: 4rem 0 2rem;
    color: var(--primary-darkest);
    width: 100%;
    margin: 0 8%;
    font-family: Inter, sans-serif;
    
}

.feature-intro{
    width: 100%;
    text-align: left;
    padding: 0 10%;
    font-size: 18px;
    font-weight: 500;
    line-height: 26.25px;
}

.feature-list{
    display: flex;
    flex-direction: row;
    /*padding:  3rem 0;*/
    position: relative;
    margin: 0 8%;
    margin-bottom: 40px;
}

.feature-list-left{
    /*padding-left: 3rem;*/
    /*padding-top: 5rem;*/
    padding-right: 40px;
    flex: 1;
}

.feature-list-left-subheading{
    font-weight: 700;
    font-size: 24px;
    line-height: 4.6rem;
    color: #05070a;
    letter-spacing: -0.44px;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 0px;
}

.feature-list-left-text{
    font-weight: 500;
    font-size: 16px;
    line-height: 26.25px;
    color: #4d4d4d;
    width: 78%;
    margin-bottom: 20px;
}

.feature-list-left-text-960{
    display: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 26.25px;
    color: #4d4d4d;
    width: 78%;
    margin-bottom: 20px;
}

.feature-list-right-image-wrapper{
    /*flex: 0 0 54%;*/
   /* margin-top: 3rem;*/
   /* padding: 1rem;*/
    box-sizing: inherit;
    height: fit-content;
    width: auto;
    /*background-color: #FFF;*/
    max-width: 150px;
    border-radius: 10px;
    /*box-shadow: var(--double-shadow);*/
    justify-content: center;
    display: flex;
    align-items: center;
}

.feature-list-img{
    /*width: 95%;*/
    max-width: 100%;
    vertical-align: middle;
    height: 172px;
}


.feature-list-left-image-wrapper{
    padding-right: 40px;
    /*flex: 0 0 54%;*/
   /* margin-top: 3rem;*/
    padding: 1rem;
    box-sizing: inherit;
    height: fit-content;
    width: auto;
    background-color: #FFF;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: var(--double-shadow);
    justify-content: center;
    display: flex;
    align-items: center;
    margin-left: 3rem;
}

.feature-list-right{
    padding-left: 100px;
    /*padding-top: 5rem;*/
    
    flex: 1;
}

.feature-list-right-text{
    font-weight: 500;
    font-size: 16px;
    line-height: 26.25px;
    color: #4d4d4d;
    width: 100%;
    margin-bottom: 20px;


}

.killer-feature{
    background: linear-gradient(90deg, hsla(214, 44%, 50%, 0.737), hsl(257, 34%, 53%)); /* Gradient from primary to lighter shade */
    color: var(--white); /* Assuming you want darker text for readability */
    font-family: Inter, sans-serif;
    padding: 5rem;
    border: 1px solid var(--gray-400); /* Optional, adds a subtle border */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.h1-killer {
    /*margin-bottom: 40px;*/
    margin-left: 15%;
    font-size: 44px;
    color: var(--primary-darkest);
    text-align: center;
    line-height: 3.5rem;
    margin-right: 15%;
}

.killer-duo {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 0 75px 0 75px;
}

.killer-content{
    margin-bottom: 18px;
    /*margin-left: 75px;*/
    /*margin-right: 52px;*/
    font-size: 18px;
    line-height: 1.75rem;
    font-weight: 400;
}

.killer-feature-img{
    width: 500px;
    max-width: 100%;
    height: fit-content;
    align-self: center;
    border-radius: 10px;
}
.killer-feature-img-1152 {
    display: none;
}

.frame-19 {
    align-items: center;
    align-self: stretch;
    background: linear-gradient(102.3deg, #684db0 0%, #258 100%);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    padding: 72px 0px;
    position: relative;
    width: 100%;
  }
  
.frame-20 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-end;
    position: relative;
    width: 100%;
  }

 .frame-21 {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    gap: 64px;
    padding: 0px 64px;
    position: relative;
    max-width: 1152px;
  }

 .text-wrapper-10 {
    align-self: stretch;
    color: var(--white);
    font-family: var(--h-2-font-family);
    font-size: var(--h-2-font-size);
    font-style: var(--h-2-font-style);
    font-weight: var(--h-2-font-weight);
    letter-spacing: var(--h-2-letter-spacing);
    line-height: var(--h-2-line-height);
    margin-top: -1px;
    position: relative;
    text-align: center;
  }
  
 .text-wrapper-11 {
    color: var(--white);
    font-family: var(--subtitle-font-family);
    font-size: var(--subtitle-font-size);
    font-style: var(--subtitle-font-style);
    font-weight: var(--subtitle-font-weight);
    letter-spacing: var(--subtitle-letter-spacing);
    line-height: var(--subtitle-line-height);
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }

  .CARD-5 {
    align-items: center;
    align-self: stretch;
    background-color: var(--white);
    border: 1px solid;
    border-color: var(--white);
    border-radius: 16px;
    box-shadow: var(--double-shadow);
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 48px;
    padding: 32px;
    position: relative;
    transition: transform 0.01s ease;
    /* Add a smooth transition effect */
    transform-origin: center center;
    /* Set the transform origin to the center */
  }
  
  .frame-10 {
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    position: relative;
    width: 100%;
    min-height:198px ;
  }
  
  
 .frame-32 {
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 48px;
    position: relative;
    width: 100%;
  }

  .starter {
    align-self: stretch;
    color: var(--primary-darkest);
    font-family: var(--h-4-font-family);
    font-size: var(--h-4-font-size);
    font-style: var(--h-4-font-style);
    font-weight: var(--h-4-font-weight);
    letter-spacing: var(--h-4-letter-spacing);
    line-height: var(--h-4-line-height);
    margin-top: -1px;
    position: relative;
    text-align: center;
  }
  .icon-instance-node {
    height: 128px !important;
    position: relative !important;
    width: 128px !important;
  }

  .automate-mobile-and-3 {
    align-self: stretch;
    color: transparent;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    min-height: 144px;
  }

  .text-wrapper-12 {
    color: #225588;
    font-weight: 600;
  }
  
  .text-wrapper-13 {
    color: #5f6b77;
    font-family: var(--bodycopy-font-family);
    font-size: var(--bodycopy-font-size);
    font-style: var(--bodycopy-font-style);
    font-weight: var(--bodycopy-font-weight);
    letter-spacing: var(--bodycopy-letter-spacing);
    line-height: var(--bodycopy-line-height);
  }

  .frame-8-feature {
    align-items: center;
    align-self: stretch;
    background-color: var(--background-blue);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    padding: 72px 0px;
    position: relative;
    width: 100%;
  }

  .text-wrapper-5 {
    align-self: stretch;
    color: var(--primary-darkest);
    font-family: var(--h-2-font-family);
    font-size: var(--h-2-font-size);
    font-style: var(--h-2-font-style);
    font-weight: var(--h-2-font-weight);
    letter-spacing: var(--h-2-letter-spacing);
    line-height: var(--h-2-line-height);
    margin-top: -1px;
    position: relative;
    text-align: center;
    margin: 2%;
  }


  .frame-9{
    display: flex;
    flex-direction: row;
    gap: 32px; /* Maintains the gap between grid items */
    align-items: center;
    justify-content: center;
    max-width: 1152px;
    margin: auto; /* Centers the grid in the parent container */
  }

  .CARD-2-copy-feature {
    align-items: center;
    align-self: center;
    background-color: var(--white);
    border: 1px solid;
    border-color: var(--white);
    border-radius: 16px;
    box-shadow: var(--double-shadow);
    display: flex;
    /*flex: 1;*/
    flex-direction: column;
    flex-grow: 1;
   /* gap: 48px;*/
    padding: 32px;
    position: relative;
    transition: transform 0.01s ease;
    /* Add a smooth transition effect */
    transform-origin: center center;
    /* Set the transform origin to the center */
    width: 400px;
    height: 448px;
    min-width: 325px;
   /* max-width: 1152px;*/
  }

  .frame-32-copy {
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    /*gap: 24px;*/
    position: relative;
    width: 100%;
  }

  .automate-mobile-and-2 {
    align-self: stretch;
    color: var(--gray-800);
    font-family: var(--bodycopy-font-family);
    font-size: var(--bodycopy-font-size);
    font-style: var(--bodycopy-font-style);
    font-weight: var(--bodycopy-font-weight);
    letter-spacing: var(--bodycopy-letter-spacing);
    line-height: var(--bodycopy-line-height);
    position: relative;
    text-align: center;
    min-height: 144px;
  }

  @media screen and (max-width: 1440px) {
    .killer-duo{
        margin: 0 36px;
    }
  }

@media screen and (max-width: 1220px) {
    .killer-duo{
        margin: 0px;
    }

    .feature-list-right {
        padding-left: 100px;
    }

    .frame-9{
        flex-direction: column;
        gap: 48px;
    }
    
    .CARD-2-copy-feature{
        width: 50%;
    }
    .feature-list{
        margin: 0 5%;
        margin-bottom: 36px;
    }
    .feature-list-img{
        
        height: 118px;
    }
}



@media screen and (max-width: 1152px) {
    .frame-21 {
        gap: 48px;
      }
      .killer-feature-img-1152{
        display: flex;
        width: 500px;
        max-width: 100%;
        height: fit-content;
        align-self: center;
        border-radius: 10px;
        margin-top: 40px;
      }
      .killer-feature-img{
       /* display: none;*/
      }
      .killer-duo{
        flex-direction: column;
       
    }
}

@media screen and (max-width: 1140px) {
    .frame-21 {
        gap: 36px;
      }
    
}

@media screen and (max-width: 1120px) {
   

    .frame-21 {
        gap: 24px;
      }
}

@media screen and (max-width: 1100px) {
   .frame-21 {
        flex-direction: column;
      }
}

@media screen and (max-width: 1080px) {
    .feature-list-right {
       /* padding-left: 40px;*/
    }

    .feature-list-left-subheading {
        line-height: 2.5rem;
    }

    .killer-content{
        margin-left: 25px;
        margin-right: 25px;
    }

   
}


@media screen and (max-width: 960px) {
    .feature-list {
        flex-direction: column;
        align-items: center;
        margin: 0 5%;
    }

    .double-side-intro {
        
        padding: 0 56px;
    }

    .feature-list-left, .feature-list-right {
        order: 1;
        padding: 0 1rem;
        text-align: center;
    }

    .feature-list-left-subheading, .feature-list-right-subheading {
        order: 1;
        margin-bottom: 20px;
    }

    .feature-list-left-image-wrapper, .feature-list-right-image-wrapper {
        order: 2;
        margin: 20px 0;
        width: 80%;
        max-width: 350px;
    }

    .feature-list-left-text-960{
        order: 3;
        margin-top: 16px;
        display: flex;
        width: 90%;
    }

    .feature-list-left-text, .feature-list-right-text {
        display: none;
      
    }

    .general-feature-title {
        font-size: 40px;
    }
    .feature-list-img{
        height: 100px;
        max-width: 100%;
    }
}

@media screen and (max-width: 860px) {
    .double-side-intro {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 72px;
        padding: 0 72px;
    }

    .text-intro{
        text-align: center;
    }

    .feature-link{
        display: flex;
        justify-content: center;
    }

    .general-feature-title {
        font-size: 36px;
    }

    .killer-content{
        margin-left: 0;
        margin-right: 0;
    }

    .frame-21 {
        padding: 0px 8%;
      }
}

@media screen and (max-width: 700px) {

    .CARD-2-copy-feature{
        width: 60%;
    }

}

@media screen and (max-width: 660px) {

    .killer-feature{
       padding: 3rem; 
    }

    .h1-killer{
        margin-left: 0;
        margin-right: 0;
        font-size: 36px
    }

    .killer-content{
        font-size: 20px;
    }
}

@media screen and (max-width: 600px) {
    .double-side-intro{
        padding: 0 36px;
    }
}


