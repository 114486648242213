
.main-demo {
    /*background-color: #F5F7FC;*/
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100vh;
    justify-content: space-between;
    min-height: 860px;
    /*gap: 48px;*/
}

.demo-double-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    
    
}

.demo-first-section{
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--white);
    padding: 20px 0px 60px 30px;
    align-items: flex-start;
    width: 100%;
    max-width: 50%;
}

.demo-first-inner-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.column-view-form{
  display: none;
}

.h1-demo {
    align-self: center;
    font-size: 32px;
    font-family: Inter, sans-serif;
    font-weight: 600;
    line-height: 1.2;
    color: var(--primary-darkest);
    margin-bottom: 24px;
    padding: 0 42px 0 42px;
}

.GoogleReCaptchaProvider{
  display: none;
}

.frame-5 {
    align-items: center;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 24px 10px 48px;
    position: relative;
    width: 100%;
  }
  .frame-8 {
    align-items: center;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    padding: 72px 0px;
    position: relative;
    width: 100%;
  }

  .frame-6-demo {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 100%;
  }

  .companies-demo {
    max-width: 100%;
    filter: grayscale(100%);
  
  transition: filter 0.3s ease;
  }

  .companies-demo:hover {
    filter: none; 
  }

  .text-wrapper-4 {
    color: var(--primary-darkest);
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }

  .img-wrapper2-demo {
    width: 200px;
    /*height: 50px;*/
    position: relative;
  }

  .frame-7-demo {
    flex: 0 0 auto;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
  .frame-17 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
  }

  .frame-18 {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    gap: 64px;
    justify-content: center;
    padding: 0px 64px;
    position: relative;
    max-width: 1152px;
  }

  .home-style{
    letter-spacing: 0;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: -1px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    position: relative;
    color: var(--gray-800);
  }

  .home-style-2{
    letter-spacing: 0;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: -1px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    position: relative;
    color: var(--gray-800);
   
    width: 100%;
  }


  .home-style:hover {
    color: var(--primary);
  }

  .text-wrapper-5 {
    align-self: stretch;
    color: var(--primary-darkest);
    font-family: var(--h-2-font-family);
    font-size: var(--h-2-font-size);
    font-style: var(--h-2-font-style);
    font-weight: var(--h-2-font-weight);
    letter-spacing: var(--h-2-letter-spacing);
    line-height: var(--h-2-line-height);
    margin-top: -1px;
    position: relative;
    text-align: center;
  }

  .primary-color-demo {
    color: var(--primary-darker);
}

  .property-1-male {
    height: 80px !important;
    position: relative !important;
    width: 80px !important;
  }

  .capterra-2 {
    align-self: flex-end;
    width:100%;
    min-width: 77px;
    max-width: 120px;
    /* padding: 0 16px; */
    display: flex;
  }
  
  .capterra-2 img {
    filter: grayscale(100%);
  }
  
  .capterra-2 img:hover{
    filter: none; 
  }

  .getapp-2 {
    align-self: flex-start;
  
    width: 100%;
    min-width: 60px;
    max-width: 80px;
  }
  
  .getapp-2 img {
    filter: grayscale(100%);
  }
  
  .getapp-2 img:hover {
    filter: none; 
  }
  
  .getapp-img-2 {
    width: 100%;
  }

  .quote-wrapper {
    display: flex;
    flex-direction: row;
     gap: 64px;
  }

  .quote-instance {
    align-self: stretch !important;
    border: 1px solid !important;
    border-color: var(--gray-400) !important;
    max-width: 480px !important;
    flex: 1;
  }
  .quote-2 {
    font-family: var(--bodycopy-large-font-family) !important;
    font-size: var(--bodycopy-large-font-size) !important;
    font-style: var(--bodycopy-large-font-style) !important;
    font-weight: var(--bodycopy-large-font-weight) !important;
    letter-spacing: var(--bodycopy-large-letter-spacing) !important;
    line-height: var(--bodycopy-large-line-height) !important;
  }

  .text-wrapper-7 {
    color: var(--primary-darkest);
    font-family: var(--subtitle-font-family);
    font-size: var(--subtitle-font-size);
    font-style: var(--subtitle-font-style);
    font-weight: var(--subtitle-font-weight);
    letter-spacing: var(--subtitle-letter-spacing);
    line-height: var(--subtitle-line-height);
    position: relative;
    text-align: center;
    width: fit-content;
  }

  @media screen and (max-width: 1200px) {
   /* .demo-double-section {
      flex-direction: column;
    }

    .demo-first-section{
      width: 100%;
      max-width: 100%;
    }*/
      
  }
  @media screen and (max-width: 1100px) {
    
  .frame-18 {
    gap: 36px;
  }
  }

  @media screen and (max-width: 1050px) {
     .frame-18 {
        gap: 24px;
      }
      .quote-wrapper {
        gap: 24px;
      }
  }

  @media screen and (max-width: 1040px) {
     .quote-wrapper {
      flex-direction: column;
    }
  
    .frame-18 {
      flex-direction: column;
    }
  
     .quote-instance {
      max-width: 480px !important;
    }
  }

  @media screen and (max-width: 960px) {
    .demo-double-section{
      height: fit-content;
      
    }
  }
  

  @media screen and (max-width: 860px) {
    .frame-18 {
        padding: 0px 8%;
      }
    .frame-8 {
        padding: 72px 5%;
    
      }
    .text-wrapper-4 {
        font-size: 25px;
      }
      .companies-demo {
        max-width: 100%;
      }

     /* .frame-7-demo {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
      }*/

      /*.img-wrapper2-demo {
        width: 256px;
        height: 50px;
        position: relative;
        align-items: center;
        margin: 10%;
        justify-content: center;
        display: flex;
      }*/

  }

  @media screen and (max-width: 700px) {
    .demo-double-section{
      flex-direction: column;
      
    }

    .demo-first-section{
      max-width: 100%;
      width: 100%;
    
      justify-content: center;
    }

    .h1-demo{
      padding-top: 60px;
    }

    .home-style{
      display: none;
    }
  }


