/* Example styling for Blog.jsx */

.main-3 {
    background-color: #F5F7FC;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 32px;
}

.hero-section-2 {
    padding-top: 6rem;
    max-width: 1110px;
    width: 100%;
    align-self: center;
}



.middle-section-2{
    border-radius: 1rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 940px;
    width: 100%;
    align-self: center;
    gap: 16px;
}

.h2-class{
    color: var(--primary-darkest);
    font-size: 2.25rem;
    font-family: Inter, sans-serif;
    font-weight: 600;
    line-height: 1.2;
    padding: 1rem;
    text-align: center;
}

.bottom-text-header{
    color: var(--primary-darkest);
    font-size: 38px;
    font-family: Inter, sans-serif;
    font-weight: 600;
    line-height: 43px;
    padding: 1rem;
}

.bottom-text{
    color: #000;
    font-size: 15px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 26.25px;
    padding: 1rem;
}



.h3-class-3{
    color: #000;
    font-size: 22px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 1.2;
    padding: 1rem;
    text-align: center;
}

.center {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
}

.h1-class-alter {
    color: var(--primary-darkest);
    font-size: 3.5rem;
    font-family: Inter, sans-serif;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
}

.center-bottom{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    max-width: 1050px;
    width: 100%;
}

.alternatives-section{
    display: flex;
    flex-direction: column;
    font-family: Inter, sans-serif;
    width: 100%;
    max-width: 1000px;
    padding: 4rem;
    align-self: center;
   
}

.altenatives{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 50px;
}

.alternative{
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.alternative-1{
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
}

.alternative-3{
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
}

.alternative-link{
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 40px;
    text-decoration-line: underline;
    color: var(--gray-800);
}

.alternatives-text{
    display: flex;
    flex-direction: column;
    gap:18px;
}
.h2-alternative{
    font-size: 38px;
    font-weight: 600;
    color: var(--primary-darkest);
    line-height: 1.2;
  
}

.primary-color {
    color: var(--primary);
}
.primary-color-2 {
    color: var(--primary);
    font-weight: 600;
}

.section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 2.5rem;
    display: flex;
    position: relative;
}

.column-container {
    z-index: 10;
    width: 100%;
    max-width: 80rem;
    flex-flow: wrap;
    justify-content: center;
    align-items: stretch;
    /*padding-top: 20px; */
    display: flex;
    position: relative;
}

.comparison-bottom-2{
    background-color: #f2f2f9;
    padding: 2.25rem;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    max-width: 1110px;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-bottom: 48px;
    width: 100%;
}
.bottom-text-part{
    text-align: left;
    display: flex;
    flex: 2;
    flex-direction: column;
}

.button-part{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

  

@media screen and (max-width: 991px) {
    .hero-section {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .section {
        padding-left: 2rem;
        padding-right: 2rem;
    }

       
.column {
    width: 50%;
    /*padding-left: 0.5rem;
    padding-right: 0.5rem;*/
}

.article-img-wrapper {
    height: 52vw;
}

.article-columns {
    margin-left: -0.5rem;
    margin-right: -0.5rem; 
     
}
}


@media screen and (max-width: 900px) {

    .altenatives{
        flex-direction: column;   
        align-self: center;
        margin-top: 10px;
    }
}


@media screen and (max-width: 800px) {
    .h3-class-3 {
        font-size: 20px;
    }

    .h1-class-alter {
        font-size: 3rem;
    }
}

@media screen and (max-width: 767px) {
    .section {
        padding: 3rem 1rem;
    }

    .container {
        flex-direction: column;
    }

    .column, .column.is-half {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {

    .comparison-bottom-2 {
        flex-direction: column;
        gap: 32px;
    }

}


@media screen and (max-width: 500px) {
    .h3-class-3 {
        font-size: 18px;
    }

    .h1-class-alter {
        font-size: 2.5rem;
    }
}

