.MAIN {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.MAIN .HEADER-CONTENT-wrapper {
  position: sticky;
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 12px 0px;
  z-index: 100;
  width: 100%;
  top: 0;
}



.MAIN .design-component-instance-node {
  flex: 0 0 auto !important;
  padding: 8px 0px !important;
}

.MAIN .button-2 {
  flex: 0 0 auto !important;
  cursor: pointer;
  ;
}




.MAIN .frame-wrapper {
  align-items: center;
  align-self: stretch;
  background: linear-gradient(248.04deg, #BBCCEE -1.75%, #BCADE1 110.22%);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  padding: 96px 0px;
  position: relative;
  width: 100%;
}

.MAIN .div-2 {
  align-items: center;
  display: flex;
  gap: 32px;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  max-width: 1280px;
}


.MAIN .div-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  /*height: 389px;*/
  justify-content: space-around;
  padding: 0px 64px;
  position: relative;
}

.MAIN .new-line {
  display: flex;
  max-width: 405px;
  width: 100%;
  margin-right: 10px;
}

.MAIN .new-line-2 {
  display: flex;
  max-width: 636px;
  width: 100%;
  margin-bottom: 10px;
}

/*
.MAIN .highlight{
  background-image: linear-gradient(135deg, #e7eff9 0%, #cfd8e3 50%, #beb7df 100%);
  border-radius: 6px;
  padding: 3px 6px;
  
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.7); 
}
*/

@keyframes drawHighlight {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.MAIN .highlight-container, .highlight {
  position: relative;
}

.MAIN .highlight-container {
  display: inline-block;
  overflow: hidden; /* Ensure the animation is contained within the borders of the container */
}

.MAIN .highlight-container:before {
  content: " ";
  display: block;
  height: 90%;
  position: absolute;
  background: #b6d0e2;
  transform: rotate(2deg);
  top: -1px;
  left: -1px;
  border-radius: 20% 25% 20% 24%;
  padding: 10px 3px 3px 10px;
  width: 0; /* Start with a width of 0 */
  animation: drawHighlight 1s forwards; /* Apply the animation, lasting for 1 second and making sure the final state persists */
}

.MAIN .automation-text{
  display: flex;
  flex-direction: row;
}

.MAIN .img-wrapper {
  max-height: 355px;
  position: relative;
  max-width: 473px;
  display: flex;
  justify-content: center;
}

/* .MAIN .video-wrapper {
    max-height: 100%;
    position: relative;
    max-width: 500px;
    position: relative;
  } 
*/
  
.MAIN .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8); /* Darker background for better focus */
    z-index: 1050; /* Above other content */
  }
  
.MAIN   .modal-video {
    max-width: 90%; /* Responsive max width */
    max-height: 90vh; /* Limit height to viewport */
  }
  
.MAIN .placeholder {
    position: relative;
    width: 500; /* Ensure full width */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
.MAIN   .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    font-size: 16px;
    background-color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

.MAIN .frame-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  position: relative;
}

.MAIN .text-wrapper-3 {
  color: var(--primary-darkest);
  font-family: var(--h-1-font-family);
  font-size: 52px;
  font-style: var(--h-1-font-style);
  font-weight: var(--h-1-font-weight);
  letter-spacing: var(--h-1-letter-spacing);
  line-height: var(--h-1-line-height);
  margin-top: -1px;
  position: relative;
}

.MAIN .p {
  color: var(--gray-800);
  font-family: var(--intro-font-family);
  font-size: var(--intro-font-size);
  font-style: var(--intro-font-style);
  font-weight: var(--intro-font-weight);
  letter-spacing: var(--intro-letter-spacing);
  line-height: var(--intro-line-height);
  position: relative;
  max-width: 550px;
}

.MAIN .worker {
  max-width: 100%;
  padding-right: 1%;
}

.MAIN .frame-5 {
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 24px 10px 48px;
  position: relative;
  width: 100%;
}

.MAIN .frame-6 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  max-width: 1084px;
  width: 100%;
}

.MAIN .text-wrapper-4 {
  color: var(--primary-darkest);
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.MAIN .frame-7 {
  flex: 0 0 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  max-width: 100%;
}


.scroller {
 max-width: 100%;

}

.scroller[data-animated="true"]{
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg, 
  transparent,
   white 20%,
   white 80%, 
   transparent);
  mask: linear-gradient(90deg, 
  transparent,
   white 20%,
   white 80%, 
   transparent);
}

.scroller-inner{
 /* flex-wrap: wrap;*/
  display: flex;

}

.scroller[data-animated="true"]  .scroller-inner{
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 40s)
  var(--_animation-direction, forwards) linear infinite;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50%));
  }
}

.MAIN .img-wrapper2 {
  width: 256px;
  height: 50px;
  position: relative;
}

.MAIN .companies {
  max-width: 100%;
  filter: grayscale(100%);
  
  transition: filter 0.3s ease;
}

.MAIN .companies:hover {
  filter: none; 
}





.MAIN .frame-8 {
  align-items: center;
  align-self: stretch;
  /*background-color: var(--background-blue);*/
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  padding: 72px 0px;
  position: relative;
  width: 100%;
}

.MAIN .text-wrapper-5 {
  align-self: stretch;
  color: var(--primary-darkest);
  font-family: var(--h-2-font-family);
  font-size: var(--h-2-font-size);
  font-style: var(--h-2-font-style);
  font-weight: var(--h-2-font-weight);
  letter-spacing: var(--h-2-letter-spacing);
  line-height: var(--h-2-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.MAIN .frame-9-copy {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates two columns */
  grid-template-rows: auto auto; /* Two rows, height adjusts automatically */
  gap: 64px; /* Maintains the gap between grid items */
  align-items: center;
  justify-content: center;
  max-width: 1152px;
  margin: auto; /* Centers the grid in the parent container */
}

.MAIN .card-upper {
  justify-content: space-between;
  align-items: center;
  align-content: center;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.MAIN .card-down {
  justify-content: space-between;
  align-items: center;
  align-content: center;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.MAIN .CARD-2-copy {
  align-items: center;
  align-self: center;
  border: 1px solid;
  border-color: #F5F7FC;
  border-radius: 16px;
  box-shadow: var(--double-shadow);
  display: flex;
  /*flex: 1;*/
  flex-direction: column;
  flex-grow: 1;
 /* gap: 48px;*/
  padding: 32px;
  position: relative;
  transition: transform 0.01s ease;
  /* Add a smooth transition effect */
  transform-origin: center center;
  /* Set the transform origin to the center */
  width: 400px;
  height: 448px;
  background-color: #F5F7FC;

 /* max-width: 1152px;*/
}

.MAIN .CARD-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--white);
  border-radius: 16px;
  box-shadow: var(--double-shadow);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 48px;
  padding: 32px;
  position: relative;
  transition: transform 0.01s ease;
  /* Add a smooth transition effect */
  transform-origin: center center;
  /* Set the transform origin to the center */
  width: 341px;
}

.CARD-2-copy:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  transform: translate(0px, -3px);
  /* You can adjust the values to control the shadow appearance */
}

.CARD-2-copy:hover .button {
  background-color: var(--primary-darker);

}

.CARD-2-copy:hover .button .request-a-demo {
  color: var(--white);
  /* Change the text color to white */
}

.MAIN .CARD-5 {
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--white);
  border-radius: 16px;
  box-shadow: var(--double-shadow);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 48px;
  padding: 32px;
  position: relative;
  transition: transform 0.01s ease;
  /* Add a smooth transition effect */
  transform-origin: center center;
  /* Set the transform origin to the center */
}

.CARD-5:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.524);
  /* You can adjust the values to control the shadow appearance */
  transform: translate(0px, -3px);
}

.MAIN .frame-10 {
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  position: relative;
  width: 100%;
  min-height:198px ;
}


.MAIN .frame-32 {
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 48px;
  position: relative;
  width: 100%;
}

.MAIN .frame-32-copy {
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  /*gap: 24px;*/
  position: relative;
  width: 100%;
}




.MAIN .starter {
  align-self: stretch;
  color: var(--primary-darkest);
  font-family: var(--h-4-font-family);
  font-size: var(--h-4-font-size);
  font-style: var(--h-4-font-style);
  font-weight: var(--h-4-font-weight);
  letter-spacing: var(--h-4-letter-spacing);
  line-height: var(--h-4-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.MAIN .icon-instance-node {
  height: 128px !important;
  position: relative !important;
  width: 128px !important;
}

.MAIN .icon-instance-node-2 {
  height: 128px !important;
  position: relative !important;
  width: 128px !important;
  padding: 1.5%;
}

.MAIN .automate-mobile-and-2 {
  align-self: stretch;
  color: var(--gray-800);
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
  position: relative;
  text-align: center;
  min-height: 144px;
}


.MAIN .automate-mobile-and-2-copy {
  align-self: stretch;
  color: var(--gray-800);
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
  position: relative;
  text-align: center;
 /* min-height: 144px;*/
}

.MAIN .design-component-instance-node-2 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}


.MAIN .design-component-instance-node-2-large {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.MAIN .CARD-3 {
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--white);
  border-radius: 16px;
  box-shadow: var(--double-shadow);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 48px;
  padding: 32px;
  position: relative;
  transition: transform 0.3s ease;
  /* Add a smooth transition effect */
  transform-origin: center center;
  /* Set the transform origin to the center */
}

.CARD-3:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transform: scale(1.009);
  /* You can adjust the values to control the shadow appearance */
}

.MAIN .frame-11 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.MAIN .frame-12 {
  align-items: center;
  align-self: stretch;
  background: linear-gradient(297.87deg, #BBCCEE -13.79%, #FFFFFF 116.74%);;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  padding: 72px 0px;
  position: relative;
  width: 100%;
}

.MAIN .frame-13 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 64px;
  position: relative;
  max-width: 1152px;
  width: 100%;
}

.MAIN .frame-14 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 260px;
}

.MAIN .text-wrapper-6 {
  align-self: stretch;
  color: var(--primary-darkest);
  font-family: var(--h-2-font-family);
  font-size: var(--h-2-font-size);
  font-style: var(--h-2-font-style);
  font-weight: var(--h-2-font-weight);
  letter-spacing: var(--h-2-letter-spacing);
  line-height: var(--h-2-line-height);
  margin-top: -1px;
  position: relative;
}

.MAIN .text-wrapper-7 {
  color: var(--primary-darkest);
  font-family: var(--subtitle-font-family);
  font-size: var(--subtitle-font-size);
  font-style: var(--subtitle-font-style);
  font-weight: var(--subtitle-font-weight);
  letter-spacing: var(--subtitle-letter-spacing);
  line-height: var(--subtitle-line-height);
  position: relative;
  text-align: center;
  width: fit-content;
}

.MAIN .frame-15 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 64px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.MAIN .CARD-4 {
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
  width: 100%;
}



.MAIN .frame-16 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  position: relative;
}

.MAIN .text-wrapper-8 {
  align-self: stretch;
  color: var(--primary-darkest);
  font-family: var(--h-4-font-family);
  font-size: var(--h-4-font-size);
  font-style: var(--h-4-font-style);
  font-weight: var(--h-4-font-weight);
  letter-spacing: var(--h-4-letter-spacing);
  line-height: var(--h-4-line-height);
  margin-top: -1px;
  position: relative;
  width: 100%;
}

.MAIN .text-wrapper-9 {
  align-self: stretch;
  color: var(--gray-800);
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
  position: relative;
  width: 100%;
}

.MAIN .frame-17 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.MAIN .frame-18 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 64px;
  justify-content: center;
  padding: 0px 64px;
  position: relative;
  max-width: 1152px;
}

.MAIN .quote-instance {
  align-self: stretch !important;
  border: 1px solid !important;
  border-color: var(--gray-400) !important;
  max-width: 480px !important;
  flex: 1;
}

.MAIN .quote-2 {
  font-family: var(--bodycopy-large-font-family) !important;
  font-size: var(--bodycopy-large-font-size) !important;
  font-style: var(--bodycopy-large-font-style) !important;
  font-weight: var(--bodycopy-large-font-weight) !important;
  letter-spacing: var(--bodycopy-large-letter-spacing) !important;
  line-height: var(--bodycopy-large-line-height) !important;
}

.MAIN .quote-wrapper {
  display: flex;
  flex-direction: row;
   gap: 64px;
}

.MAIN .property-1-male {
  height: 80px !important;
  position: relative !important;
  width: 80px !important;
}

.MAIN .frame-19 {
  align-items: center;
  align-self: stretch;
  background: linear-gradient(102.3deg, #684DB0 0%, #225588 100%);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  padding: 72px 0px;
  position: relative;
  width: 100%;
}

.MAIN .frame-20 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.MAIN .text-wrapper-10 {
  align-self: stretch;
  color: var(--white);
  font-family: var(--h-2-font-family);
  font-size: var(--h-2-font-size);
  font-style: var(--h-2-font-style);
  font-weight: var(--h-2-font-weight);
  letter-spacing: var(--h-2-letter-spacing);
  line-height: var(--h-2-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.MAIN .text-wrapper-11 {
  color: var(--white);
  font-family: var(--subtitle-font-family);
  font-size: var(--subtitle-font-size);
  font-style: var(--subtitle-font-style);
  font-weight: var(--subtitle-font-weight);
  letter-spacing: var(--subtitle-letter-spacing);
  line-height: var(--subtitle-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.MAIN .frame-21 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 64px;
  padding: 0px 64px;
  position: relative;
  max-width: 1152px;
}

.MAIN .design-component-instance-node-3 {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  width: unset !important;

}

.MAIN .automate-mobile-and-3 {
  align-self: stretch;
  color: transparent;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  min-height: 144px;
}

.MAIN .text-wrapper-12 {
  color: #225588;
  font-weight: 600;
}

.MAIN .text-wrapper-13 {
  color: #5f6b77;
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
}

.MAIN .frame-22 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 64px;
  justify-content: center;
  position: relative;
  width: 750px;


  /*1152*/

}

.MAIN .frame-23 {
  align-items: center;
  background-color: var(--background-blue);
  border-radius: 16px;
  box-shadow: var(--double-shadow);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  justify-content: center;
  padding: 32px;
  position: relative;
}

.MAIN .frame-24 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.MAIN .text-wrapper-14 {
  align-self: stretch;
  color: var(--primary-darkest);
  font-family: var(--subtitle-font-family);
  font-size: var(--subtitle-font-size);
  font-style: var(--subtitle-font-style);
  font-weight: var(--subtitle-font-weight);
  letter-spacing: var(--subtitle-letter-spacing);
  line-height: var(--subtitle-line-height);
  position: relative;
}

.MAIN .frame-25 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.MAIN .frame-26 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
  width: 100%;
}

.MAIN .frame-27 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  justify-content: center;
  position: relative;
}

.MAIN .input-label-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.comment-bottom-2 {
  flex-direction: row;
  justify-content: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
}


.capterra-2 {
  align-self: flex-end;
  width:100%;
  min-width: 77px;
  max-width: 120px;
  /* padding: 0 16px; */
  display: flex;
}

.capterra-2 img {
  filter: grayscale(100%);
}

.capterra-2 img:hover{
  filter: none; 
}

.getapp-2 {
  align-self: flex-start;

  width: 100%;
  min-width: 60px;
  max-width: 80px;
}

.getapp-2 img {
  filter: grayscale(100%);
}

.getapp-2 img:hover {
  filter: none; 
}

.getapp-img-2 {
  width: 100%;
}

.comment-bottom-2 img {
  filter: grayscale(50%); /* Applies a black and white filter */
}

.comment-bottom-2 img:hover {
  filter: none; /* Removes the filter on hover (back to original colors) */
}



.MAIN .input-label-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 16px;
  justify-content: center;
  position: relative;
}

.MAIN .input-label-2 {
  align-items: flex-start !important;
  align-self: stretch !important;
  display: flex !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  width: 100% !important;
}



.MAIN .input-label-3 {
  margin-right: -1px !important;
}

.MAIN .frame-28 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.MAIN .input-label-4 {
  flex: 1 !important;
  flex-grow: 1 !important;
  width: unset !important;
}

.MAIN .tb-1-1 {
  height: 40.44px !important;
  position: relative !important;
  width: 109.03px !important;
}

.MAIN .text-wrapper-15 {
  color: var(--gray-400);
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
  position: relative;
  width: fit-content;
}




.MAIN .text-wrapper-16 {
  color: var(--gray-400);
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
  margin-top: -1px;
  position: relative;
  width: 83px;
}

.MAIN .text-wrapper-17 {
  align-self: stretch;
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-decoration: underline;
}

@media screen and (max-width: 1210px) {

  .MAIN .footer {
    gap: 120px;
  }

  .MAIN .frame-13 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


  .MAIN .frame-16 {
    justify-content: center;
    align-items: center;
  }

  .MAIN .text-wrapper-6 {

    text-align: center;
    font-size: var(--h-1-font-size);
    align-self: center;
    justify-content: center;

  }

  .MAIN .frame-14 {
    width: 80%;
    align-items: center;
  }


  .MAIN .frame-15 {
    width: 100%;
    align-items: center;
  }

  .MAIN .CARD-4 {
    width: 80%;
    align-self: center;
  }

}

@media screen and (max-width: 1190px) {
  .MAIN .frame-9-copy {
    gap: 50px;
  }
}




@media screen and (max-width: 1152px) {
  .MAIN .frame-9-copy {
    gap: 40px;
  }

  .MAIN .frame-21 {
    gap: 48px;
  }

  .MAIN .footer {
    gap: 70px;
  }

}


@media screen and (max-width: 1140px) {
  .MAIN .frame-9-copy {
    gap: 30px;
  }

  .MAIN .frame-21 {
    gap: 36px;
  }
}

@media screen and (max-width: 1120px) {
  .MAIN .frame-9-copy {
    gap: 20px;
  }

  .MAIN .frame-21 {
    gap: 24px;
  }

}

@media screen and (max-width: 1100px) {
  
  .MAIN .frame-9-copy {
    display: flex;
    flex-direction: column;
    gap: 36px;
    padding: 0 8%;
  }

  .MAIN .frame-18 {
    gap: 36px;
  }

  .MAIN .CARD-2-copy {
    width: 100%;
    align-self: center;
    height: 500px;
  }

  .MAIN .frame-21 {
    flex-direction: column;
  }
}

@media screen and (max-width: 1050px) {
  .MAIN .frame-18 {
    gap: 24px;
  }
  .MAIN .quote-wrapper {
    gap: 24px;
  }
}

@media screen and (max-width: 1040px) {
  .MAIN .quote-wrapper {
    flex-direction: column;
  }

  .MAIN .frame-18 {
    flex-direction: column;
  }

  .MAIN .quote-instance {
    max-width: 480px !important;
  }
}

@media screen and (max-width: 960px) {

  .MAIN .footer {
    gap: 40px;
  }

  .MAIN .frame-28 {
    flex-direction: column;
  }

  .MAIN .input-label-4 {
    width: 100% !important;
  }


  .MAIN .HEADER-CONTENT-wrapperItems {
    position: relative;
  }



  .MAIN .design-component-instance-node-large {
    display: none;
  }

  .MAIN .button-2-large {
    display: none;
  }

  .MAIN .design-component-instance-node-small {}

  .MAIN .button-2-small {}

  .menu-container {
    position: relative;
    display: inline-block;
  }




  /* Styles for the menu list */
  .menu-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  /* Styles for menu items */
  .menu-list li {
    padding: 8px 16px;
  }

  /* Styles for menu item links */
  .menu-list li a {
    text-decoration: none;
    color: #333;
    display: block;
  }

  /* Hover effect for menu items */
  .menu-list li a:hover {
    background-color: #f0f0f0;
  }

  .MAIN .div-wrapper {
    width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-left: 5%;
  }
}


@media screen and (max-width: 860px) {
  .MAIN .frame-18 {
    padding: 0px 8%;
  }

  .MAIN .frame-21 {
    padding: 0px 8%;
  }


  .MAIN .text-wrapper-15 {
    text-align: center;
  }

  .MAIN .frame-8 {
    padding: 72px 5%;

  }

  .MAIN .footer {
    flex-direction: column;
  }


  .MAIN .frame-22 {
    max-width: 750px !important;
    width: 100%;
    padding: 0% 5% 0% 5%;
  }

  .home__hero-text-wrapper {
    padding-bottom: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  .MAIN .div-wrapper {
    width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 5%;
    text-align: center;
  }

  
  .scroller .scroller-inner {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }

  .scroller[data-animated="true"]  .scroller-inner {
    width: max-content;
    flex-wrap: nowrap;
    animation: scroll var(--_animation-duration, 40s)
    var(--_animation-direction, forwards) linear infinite;
    flex-direction: row;
  }



  .MAIN .img-wrapper {
    max-height: 355px;
    position: relative;
    max-width: 473px;
    margin-top: 8%;
    align-items: center;
  }

  .MAIN .worker {
    max-width: 80%;
    align-items: center;
    margin: 0 0 0 10px;
    padding-right: 0;
  }


  .MAIN .div-2 {
    flex: none;
    justify-content: space-between;
    align-items: center;
    max-width: 1152px;
    display: flex;
    position: relative;
    flex-direction: column;
  }

  .MAIN .frame-4 {
    align-items: center;
  }

  /*
  .MAIN .companies {
    max-width: 110%;
  }
*/
  .MAIN .img-wrapper2 {
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .MAIN .text-wrapper-4 {
    font-size: 25px;
  }
}

@media screen and (max-width: 780px) {


  .scroller[data-animated="true"] .img-wrapper2 {
    width: 192px;
  
}
}

@media screen and (max-width: 500px) {

  .MAIN .automation-text {
    flex-direction: column;
  }


  .scroller[data-animated="true"] .img-wrapper2 {
    width: 140px;
  
}

}

@media screen and (max-width: 430px) {
    .MAIN .HEADER-CONTENT-wrapper {
      align-items: center;
      align-self: stretch;
      background-color: var(--white);
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      gap: 10px;
      justify-content: center;
      padding: 12px 0px;
      z-index: 100;
      width: 100%;
      top: 0;
    }
  
    .MAIN .div-2 {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 100%;
    }
  
    .MAIN .div-wrapper {
      width: 100%;
      padding: 0px 16px;
    }
  
    .MAIN .text-wrapper-3 {
      font-size: 32px;
      text-align: center;
      margin-top: 8px;
    }
  
    .MAIN .text-wrapper-4 {
      font-size: 16px;
      text-align: center;
      margin-top: 8px;
    }
  
    .MAIN .frame-5 {
      flex-direction: column;
      gap: 8px;
      padding: 16px 0px 24px;
    }
  
    .MAIN .frame-6 {
      max-width: 90%;
      gap: 24px;
    }
  
    .MAIN .CARD-2-copy,
    .MAIN .CARD-2,
    .MAIN .CARD-3,
    .MAIN .CARD-4,
    .MAIN .CARD-5 {
      width: 100%;
      padding: 16px;
      margin-bottom: 16px;
    }
  
    .MAIN .frame-9-copy {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  
    .MAIN .quote-wrapper {
      flex-direction: column;
      gap: 16px;
    }
  
    .MAIN .frame-18 {
      flex-direction: column;
      gap: 16px;
    }
  
    .MAIN .img-wrapper {
      max-height: 200px;
      max-width: 100%;
    }
  
    .MAIN .footer {
      flex-direction: column;
      gap: 16px;
      padding: 16px;
    }
  
    .MAIN .frame-8 {
      padding: 48px 16px;
    }
  
    .MAIN .text-wrapper-5 {
      font-size: 20px;
    }
  
    .MAIN .frame-22 {
      width: 100%;
      padding: 0px 16px;
    }
  
    .MAIN .text-wrapper-15 {
      font-size: 14px;
      text-align: center;
    }
  
    .scroller .scroller-inner {
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }
  
    .MAIN .frame-23 {
      padding: 24px;
      gap: 16px;
    }
  
    .MAIN .frame-24 {
      padding: 16px;
    }
  
    .MAIN .frame-25 {
      padding: 16px;
    }
  
    .MAIN .frame-26 {
      padding: 16px;
    }
  
    .MAIN .frame-27 {
      gap: 16px;
    }
  
    .MAIN .frame-28 {
      flex-direction: column;
      gap: 16px;
    }
  
    .MAIN .placeholder {
      position: relative;
      width: 100%; /* Ensure full width */
      max-width: 100%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    img {
      max-width: 100%;
      height: auto;
    }
  }
  