.button {
  all: unset;
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 8px 16px;
  position: relative;
}

.button .request-a-demo {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.button.outline {
  border: 1px solid;
}

.button.outline.normal {
  background-color: var(--white);
  border-color: var(--primary-darker);
  box-shadow: var(--double-shadow-transparent);
}

.button.cta.normal {
  box-shadow: var(--double-shadow-transparent);
}

.button.hover.outline {
  background-color: var(--primary);
  border-color: var(--primary);
}

.button.hover.cta {
  box-shadow: var(--double-shadow);
}

.button.regular.normal.solid {
  background-color: var(--primary-darker);
}

.button.cta.text.normal {
  background-color: #4826a0;
}

.button.cta.normal.solid {
  background-color: #4826a0;
}

.button.hover.cta.solid {
  background-color: var(--purple-1);
}

.button.hover.regular.solid {
  background-color: var(--primary);
}

.button.hover.regular.outline {
  background-color: var(--primary);
}

.button.hover.text.cta {
  background-color: var(--purple-1);
}

.button.solid .request-a-demo {
  color: #ffffff;
}

.button.outline.normal .request-a-demo {
  color: var(--primary-darker);
}

.button.outline.normal .request-a-demo {
  color: var(--primary-darker);
}

.button.hover.outline .request-a-demo {
  color: var(--white);
}

.button.hover.text .request-a-demo {
  color: var(--primary);
}

.button.text.normal .request-a-demo {
  color: var(--gray-800);
}