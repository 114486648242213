.comparison-table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 1110px;
    margin: 68px 0 20px 0;
    align-self: center;
    font-family: Inter, sans-serif;
    border: 1px solid #e5e7eb; /* Change border color here */
  
    overflow: hidden;
  
  }
  .table-title{
    font-family: Inter, sans-serif;
    font-size: 25px !important;
    padding: 45px 60px !important;
    color: var(--primary-darkest);
    width: 100%;
  }

  .tick{
    color:#19b519;
    font-size: 23px !important;
  }

  .cross{
    color:#a01e1e;
    font-size: 23px !important;
  }
  
  .table-header {
    display: flex;
    font-weight: bold;
    width: 100%;
    max-width: 1110px;
  }
  
  .table-header > div {
    flex: 1;
    padding: 25px 10px;
    text-align: center;
    font-size: 20px;
    border: 1px solid #e5e7eb;
    color: var(--primary-darkest);
    width: 100%;
    max-width: 1110px;
  }
  
  .table-row {
    display: flex;
    width: 100%;
    max-width: 1110px;
  }

  
  .table-row > div {
    flex: 1;
    padding: 8px;
    text-align: center;
    padding: 15px 10px;
    font-size: 17px;
    border: 1px solid #e5e7eb; /* Change border color here */
    color: var(--gray-800);
    font-weight: 600;
    width: 100%;
    max-width: 1110px;
  }

  .table-header-feature{
    width: 33.333% !important;
  }

  .pinkish-color-feature{
    width: 33.333% !important;
    background-color: rgba(245,233,255,.769);
  }

  .greenish-color-feature{
    width: 33.333% !important;
    background-color: rgb(228 ,253, 244, .769);
  }

  .pinkish-color {
    background-color: rgba(245,233,255,.769); /* Pinkish background color */
  }
  
  .greenish-color {
    background-color: rgb(228 ,253, 244, .769); /* Greenish background color */
  }

 


  @media screen and (max-width: 400px) {

    .table-row > div {
      font-size: 15px;
    }
    
  }