.bottom-section{
    background-color: #f2f2f9;
    border-radius: 1rem;
    padding: 4rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 720px;
    width: 100%;
    align-self: center;
    gap: 16px; 
    margin-bottom: 96px;
}

.thank-you-message {
    
    color: var(--primary-darkest);
    font-family: Inter, Sans-serif;
    font-size: 1.3rem;
   
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }

.h1-class-3 {
    color: var(--primary-darkest);
    font-size: 2.25rem;
    font-family: Inter, sans-serif;
    font-weight: 600;
    line-height: 1.2;
}

.paragraph-divider {
    background: #736edb;
    background: -moz-linear-gradient(90deg,var(--primary-darkest) 0,var(--primary-lightest) 100%);
    background: -webkit-linear-gradient(90deg,var(--primary-darkest),var(--primary-lightest));
    background: linear-gradient(90deg,var(--primary-darkest),var(--primary-lightest));
    border-radius: 2px;
    display: inline-block;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#736edb",endColorstr="#e84fd9",GradientType=1);
    height: 4px;
    margin-bottom: 10px;
    width: 50px;
}

.h2-class-2{
    color: #000;
    font-size: 1.25rem;
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
}

.subscribe-button{
    flex: 0 0 auto !important;
    cursor: pointer;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: 56px;
}

.form-style{
    display: flex;
    align-items: center;
    width: 100%; 
    justify-content: center; 
    padding: 1.5rem 0 0;
}

.form-input-style{
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    background-color: white;
    color: black;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    height: 56px;
}

@media screen and (max-width: 540px) {

    .bottom-section {
        padding: 2rem;
}

.subscribe-button {
    height: 40px;
    font-size: 13px;
}

.form-input-style{
    height: 40px;
}

}