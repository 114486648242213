

.main-2 {
    background-color: #F5F7FC;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 48px;
}

.hero-section {
    padding-top: 6rem;
}



.middle-section{
    background-color: #f2f2f9;
    border-radius: 1rem;
    padding: 4rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 80rem;
    width: 100%;
    align-self: center;
    gap: 16px;
}

.h2-class{
    color: var(--primary-darkest);
    font-size: 2.25rem;
    font-family: Inter, sans-serif;
    font-weight: 600;
    line-height: 1.2;
    padding: 1rem;
    text-align: center;
}




.h3-class{
    color: #000;
    font-size: 1rem;
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 1.2;
    padding: 1rem;
    text-align: center;
}

.center {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
}

.h1-class {
    color: #000;
    font-size: 5rem;
    font-family: Inter, sans-serif;
    font-weight: 600;
    line-height: 1.2;
}


.primary-color {
    color: var(--primary);
}
.primary-color-2 {
    color: var(--primary);
    font-weight: 600;
}

.section-blog {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 2.5rem;
    display: flex;
    position: relative;
}

.news-section{
    display: flex;
    position: relative;
    margin: 0rem 2.5rem;
    max-width: 1280px;
    align-self: center;
}
.news{
    width: 100%;
    perspective: 35rem;
    padding: 1rem;
    display: flex;
    font-family: Inter, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    letter-spacing: 0.005rem;
}

.news-items:hover {
    transform: translate(0, -3px);
    box-shadow: 0 0 15px #0000004d;
}

.news-items{
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    background-color: 1px solid var(--white);
    border-color: var(--white);
    box-shadow: var(--double-shadow);
    border-radius: 16px;
    align-items: stretch;
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-decoration: none;
    max-width: 100%;
}

.news-image-wrapper{
    width: 50%;
    height: 36vw;
    max-height: 26rem;
    min-height: 100%;
    position: relative;
    overflow: hidden;
}

.news-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
    vertical-align: middle;
}

.news-text{
    padding: 2rem;
    width: 100%;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    position: relative;
}

.news-date{
    width: 100%;
    color: var(--gray-800);
    letter-spacing: 0.01rem;
    white-space: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;
    font-size: 0.83rem;
    font-weight: 500;
    display: flex;
}
.news-content{
    flex: 1;
}

.news-title{
    margin-bottom: 1.3rem;
    color: var(--primary-darkest);
    font-weight: 600;
    text-align: left;
    font-size: 3rem;
    line-height: 1.1;
}

.news-desc{
    margin-bottom: 2rem;
    color: var(--gray-800);
    line-height: 1.5;
    font-family: Inter, sans-serif;
    font-size: 1rem;
}

.news-bottom{
    width: 100%;
    max-width: 100%;
    grid-column-gap: 0px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    color: var(--gray-800);
    text-decoration: none;
}

.column-container {
    z-index: 10;
    width: 100%;
    max-width: 80rem;
    flex-flow: wrap;
    justify-content: center;
    align-items: stretch;
    /*padding-top: 20px; */
    display: flex;
    position: relative;
}

.company-name {
    margin-top: 20px;
    font-size: 3rem;
}

.article-columns {
    flex-wrap: wrap;
    justify-content: flex-start;
  /*  margin-left: -1rem;
    margin-right: -1rem; */
    display: flex;
    width: 100%;
}


.column {
    padding: 1rem;
    display: flex;
    justify-content: center;
    width: 33.33333%;
}

.column-wrap {
    width: 100%
}

.column h2 {
    margin-bottom: 15px;
}

.article-item {
    width: 100%;
    perspective: 35rem;
    display: flex;
    text-decoration: none;
    align-items: center;
    align-self: stretch;
    background-color: var(--white);
    border-color: var(--white);
    border-radius: 16px;
    box-shadow: var(--double-shadow);
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    transition: transform 0.01s ease;
    /* Add a smooth transition effect */
    transform-origin: center center;
    overflow: hidden;
}

.article-contents{
display: flex;
flex-direction: column;
gap: 10px;
}

.article-item:hover {
    transform: translate(0, -3px);
    box-shadow: 0 0 15px #0000004d;
}

.article-img-wrapper {
    width: 100%;
    height: 16vw;
    max-height: 14rem;
    position: relative;
    overflow: hidden;
    object-fit: cover;
}

.article-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
    border-radius: 16px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.card-content {
    width: 100%;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.5rem;
    display: flex;
    position: relative;
    font-family: Inter, sans-serif;
}

.date {
    width: 100%;
    color: var(--smoke-default);
    letter-spacing: .01rem;
    white-space: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;
    font-size: .83rem;
    font-weight: 500;
    display: flex;
    color: var(--gray-800);
}

.title {
    flex: 1;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin: 0.5rem;
    box-sizing: border-box;
    display: block;
}

.title-text {
    color: var(--primary-darkest);
    text-decoration: none;
    font-weight: var(--h-1-font-weight);
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.35;
    min-height: 52px;
}

.card-bottom {
    width: 100%;
    max-width: 100%;
    grid-column-gap: 0px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    color: var(--gray-800);
    text-decoration: none;
}

.author-2 {
    max-width: 100%;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    display: flex;
    gap: 5px;
}

.author-img {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    overflow: hidden;
}

.image {
    width: 36px;
    height: auto;
}

.author-name {
    font-size: .85rem;
    font-family: Inter, sans-serif;
    font-size: .83rem;
    line-height: 1.5;
}

.tag {
    grid-column-gap: 0.375rem;
    color: #344054;
    white-space: nowrap;
    background-color: #f2f4f7;
    border-radius: 10rem;
    align-items: center;
    font-size: .83rem;
    display: flex;

    text-transform: none;
    padding: 0.25rem 0.75rem;
    font-weight: 500;
    position: static;
}


.divider {
    width: 100%;
    height: 1px;
    color: #eaecf0;
    background-color: #eaecf0;
    -webkit-text-stroke-color: #eaecf0;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
}

.read-time {
    display: flex;
}

.min-read-txt {
    margin-left: 0.35rem;
}

@media screen and (max-width: 1272) {
    
    .news-image-wrapper{
       
        width: 60%;
    }
}


@media screen and (max-width: 1100px) {
    .news-items{
        flex-direction: column;
    }

    .news-image-wrapper{
        min-height: auto;
        width: 100%;
    }
}

@media screen and (max-width: 991px) {
    .hero-section {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .section {
        padding-left: 2rem;
        padding-right: 2rem;
    }

       
.column {
    width: 50%;
    /*padding-left: 0.5rem;
    padding-right: 0.5rem;*/
}

.article-img-wrapper {
    height: 52vw;
}


    .news-image-wrapper {
        height: 52vw;
    }


.article-columns {
    margin-left: -0.5rem;
    margin-right: -0.5rem; 
     
}
}

@media screen and (max-width: 767px) {
    .section {
        padding: 3rem 1rem;
    }

    .container {
        flex-direction: column;
    }

    .column, .column.is-half {
        width: 100%;
    }
}



@media screen and (max-width: 700px) {
    .news-title{
        font-size: 2rem;
    }
}


@media screen and (max-width: 550px) {
    .news-title{
        font-size: 1.75rem;
    }
    

}

@media screen and (max-width: 450px) {
    .news-title{
        font-size: 1.5rem;
    }

}


