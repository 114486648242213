/* src/screens/ArcadePage.css */
.arcade-container {
    position: relative;
    width: 100%;
    height: 80vh; /* Adjust height as needed */
    margin: 20px 0; /* Optional: Add some spacing */
  }
  
  .arcade-collection {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    color-scheme: light;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .arcade-container {
      height: 60vh;
    }
  }
  
  @media (max-width: 480px) {
    .arcade-container {
      height: 50vh;
    }
  }
  