.input-label {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 277px;
}

.input-label .label {
  align-self: stretch;
  color: var(--gray-800);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
}
