h1 {
    font-size: 30px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    /* Other styles */
  }
  
  /* Example styles for h2 */
  h2 {
    font-size: 27px;
    font-weight: bold;
    color: #555;
    margin-top: 20px;
    /* Other styles */
  }
  h3{
    font-size: 24px;
    font-weight: bolder;
    color: #555;
    margin-top: 20px;
    margin-bottom: 15px;
    /* Other styles */
  }

  h4 {
    font-size: 18px;
    font-weight: bold;
    color: #555;
    margin-top: 20px;
    margin-bottom: 15px;
    /* Other styles */
  }
  em{
    font-style: italic;
  }

strong{
    font-weight:bold;
   }


   ul, ol {
    list-style-type: auto;
    padding-left: 20px; /* Remove default padding */
  }
  
  /* Example: Add custom style to list items */
  ul li {
    margin-bottom: 5px;
    /* Your styles here */
  }
  
  ol li {
    margin-bottom: 10px;
    /* Your styles here */
  }

.main {
    background-color: #F5F7FC;
    display: flex;
    flex-direction: column;
    position: relative;
}

.hero-section-2{
    padding-top: 6rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 3rem 2.5rem 5rem; */
    display: flex;
    position: relative;
}

.section-2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 2.5rem;
    display: flex;
    position: relative;
}

.starter-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    max-width: 64rem;
    text-align: center;
    gap: 32px;
}

.label-2 {
    background-color: var(--primary-lightest);
    color: var(--primary-darker);
    margin-left: 0;
    margin-right: 0;
    position: relative;
    z-index: 12;
    border: 1px none var(--primary-lighter);
    border-radius: 0.3rem;
    padding: 0.25rem 0.75rem;
    font-size: .83rem;
    font-weight: 600;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: auto;
    text-decoration: none;
    font-family:  Inter, sans-serif;
}

.middle-starter {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin: 2rem;
    text-align: center;
    color: #475467;
    font-family: Inter, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: .005rem;
    gap: 16px;
}


.author {
    max-width: 100%;
    align-items: center;
    font-weight: 600;
    display: flex;
    text-align: center;
    flex-direction: column;
    gap: 1rem;
}

.author-img2 {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    overflow: hidden;
}

.image {
    width: 36px;
    height: auto;
}

.author-name {
    font-size: .85rem;
    font-family: Inter, sans-serif;
    font-size: .83rem;
    line-height: 1.5;
}

.divider-section-2 {
    padding-top: 0;
    padding-bottom: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 2rem;
    display: flex;
    position: relative;
    max-width: 720px;
    z-index: 10;
    width: 100%;
    flex-flow: wrap;
    justify-content: center;
    align-items: stretch;
    display: flex;
    position: relative;
}

.date-read-section {
    width: 100%;
    color: var(--gray-800);
    letter-spacing: .01rem;
    white-space: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;
    font-size: .83rem;
    font-weight: 500;
    display: flex;
    font-family: Inter, sans-serif;
}

.content-link {
    color: #007bff;
    /* Link color */
    text-decoration: none;
    /* Remove underline by default */
}

.date-divider {
    width: 100%;
    height: 2px;
    color: #eaecf0;
    -webkit-text-stroke-color: #eaecf0;
    background-color: #eaecf0;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
}

.read-time-divider {
    display: flex;
}

.read-time {
    display: flex;
}

.min-read-txt {
    margin-left: 0.35rem;
}

.content-text {
    display: flex;
}

.h1-class-2 {
    color: var(--primary-darkest);
    font-size: 4rem;
    font-family: Inter, sans-serif;
    font-weight: 600;
    line-height: 1.2;
}

.content-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 2.5rem 5rem 2.5rem;
    display: flex;
    position: relative;
}

.content-container {
    max-width: 64rem;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-flow: wrap;
    justify-content: space-around;
    align-items: flex-start;
    margin-left: 0;
    display: flex;
    width: 100%;
}

.content {
    width: 100%;
    max-width: 840px;
    color: #475467;
    font-family: Inter, sans-serif;
    font-size: 1.10rem;
    font-weight: 410;
    line-height: 1.75;
    gap: 32px;
    display: flex;
    flex-direction: column;
}

.content-img {
    max-width: 720px;
    width: 100%;
    border-radius: 3%;
    align-self: center;
}


@media screen and (max-width: 991px) {
    .hero-section-2 {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .section-2 {
        padding-left: 2rem;
        padding-right: 2rem;
    }

}

@media screen and (max-width: 767px) {
    .h1-class-2 {
        font-size: 3.25rem;
    }

    .section-2 {
        padding: 3rem 0rem;
    }
}


@media screen and (max-width: 495px) {
    .h1-class-2 {
        font-size: 3rem;
    }

   

    .middle-starter {
        margin: 2rem 0rem; 
}

.divider-section-2 {
    padding: 2rem 0rem; 
}

.content {
    font-size: 1rem;
}
}