.HEADER {
    position: sticky;
    align-items: center;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    padding: 12px 0px;
    z-index: 100;
    width: 100%;
    top: 0;
}

.HEADER .HEADER-CONTENT {
    align-items: center;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    max-width: 1152px;
    /* Changed width to max-width for responsiveness */
    width: 100%;
    /* Added to ensure responsiveness */
    margin: 0 auto;
    /* Center the content */
}

.HEADER .HEADER-CONTENT-wrapper {
    position: sticky;
    align-items: center;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    padding: 12px 0px;
    z-index: 100;
    width: 100%;
    top: 0;
  }

.HEADER .HEADER-CONTENT-wrapperItems {
    position: relative;
}

.HEADER .tb {
    height: 40.44px !important;
    margin-bottom: -0.23px !important;
    margin-top: -0.21px !important;
    position: relative !important;
    width: 109.03px !important;
}

.HEADER .frame-3 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 32px;
    justify-content: center;
    position: relative;
}


.HEADER  .button-2-large {
    flex: 0 0 auto !important;
    cursor: pointer;
    ;
  }

.HEADER .menu-icon {
    display: none;
}

.menu-dropdown {
    display: none;
}


@media screen and (max-width: 1180px) {
    .HEADER .tb {
        position: relative !important;
        top: 0;
        left: 0;
        transform: translate(20%, 0%);
    }

    .HEADER .frame-3 {
        transform: translate(-20%, 0%);
        position: relative;
    }
}

@media screen and (max-width: 960px) {

    .HEADER .HEADER-CONTENT-wrapperItems {
        position: relative;
    }

    .HEADER .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-50%, -50%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .HEADER .tb {
        position: relative !important;
        top: 0;
        left: 0;
        transform: translate(20%, 0%);
    }

    .HEADER .design-component-instance-node-large {
        display: none;
    }

    .HEADER .button-2-large {
        display: none;
    }

    .HEADER .design-component-instance-node-small {}

    .HEADER .button-2-small {}

    .menu-container {
        position: relative;
        display: inline-block;
    }

    .menu-dropdown {
        position: absolute;
        border-radius: 10%;
        transform: translate(-30%, 70%);
        min-width: 150px;
        background-color: #fff;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        display: flex;
    }

    /* Show the dropdown when menu is open */
    .menu-container:hover .menu-dropdown {
        display: block;
    }

    /* Styles for the menu list */
    .menu-list {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    /* Styles for menu items */
    .menu-list li {
        padding: 8px 16px;
    }

    /* Styles for menu item links */
    .menu-list li a {
        text-decoration: none;
        color: #333;
        display: block;
    }

    /* Hover effect for menu items */
    .menu-list li a:hover {
        background-color: #f0f0f0;
    }


}