/* Example styling for Blog.jsx */

.main-3 {
    background-color: #F5F7FC;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 32px;
}

.hero-section-comp {
    padding-top: 6rem;
}


.company-name-comp{
    margin-top: 20px;
    font-size: 3rem;
    display: flex;
    flex-direction: column;
    max-width: 1110px;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-self: center;
}

.middle-section-2{
    border-radius: 1rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 940px;
    width: 100%;
    align-self: center;
    gap: 16px;
}

.h2-class{
    color: var(--primary-darkest);
    font-size: 2.25rem;
    font-family: Inter, sans-serif;
    font-weight: 600;
    line-height: 1.2;
    padding: 1rem;
    text-align: center;
}

.bottom-text-header{
    color: var(--primary-darkest);
    font-size: 38px;
    font-family: Inter, sans-serif;
    font-weight: 600;
    line-height: 43px;
    padding: 1rem;
}

.bottom-text{
    color: #000;
    font-size: 15px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 26.25px;
    padding: 1rem;
}



.h3-class-2{
    color: #000;
    font-size: 22px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 1.2;
    padding: 1rem;
    text-align: center;
}

.center {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
}

.h1-class-comp {
    color: var(--primary-darkest);
    font-size: 3.5rem;
    font-family: Inter, sans-serif;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
}

.center-bottom{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    max-width: 1050px;
    width: 100%;
}

.frame-feature{
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1050px;
    align-self: center;
    gap: 64px;
    margin: 48px 0px;
}

.feature-header{
    color: var(--primary-darkest);;
    margin-top: 20px;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    line-height: 1.75rem;
    min-height: 56px;

}

.feature-icon{
    width: 100px;
    height: 100px;
}

.feature-text{
    text-align: center;
    color: var(--gray-800);
    font-family: Inter, sans-serif;
    line-height: 1.3rem;
    margin-top: 20px;
}

.feature{
    font-family: Inter, sans-serif;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 300px;
    min-height: 320px;
}

.feature-top{
    display: flex;
    flex-direction: column;
    align-items: center;
}

  
  

.primary-color {
    color: var(--primary);
}
.primary-color-2 {
    color: var(--primary);
    font-weight: 600;
}

.section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 2.5rem;
    display: flex;
    position: relative;
}

.column-container {
    z-index: 10;
    width: 100%;
    max-width: 80rem;
    flex-flow: wrap;
    justify-content: center;
    align-items: stretch;
    /*padding-top: 20px; */
    display: flex;
    position: relative;
}

.comparison-bottom{
    background-color: #f2f2f9;
    padding: 2.25rem;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    max-width: 1110px;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-bottom: 48px;
    width: 100%;
}
.bottom-text-part{
    text-align: left;
    display: flex;
    flex: 2;
    flex-direction: column;
}

.button-part{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}


  @media screen and (max-width: 1100px) {
    .frame-feature{
        gap:32px;
    }
  }


  @media screen and (max-width: 1020px) {
    .frame-feature{
        flex-direction: column;
    }

    .feature {
        width: 600px;
    }
  }
  
  

@media screen and (max-width: 991px) {
    .hero-section-comp {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .section {
        padding-left: 2rem;
        padding-right: 2rem;
    }

       
.column {
    width: 50%;
    /*padding-left: 0.5rem;
    padding-right: 0.5rem;*/
}

.article-img-wrapper {
    height: 52vw;
}

.article-columns {
    margin-left: -0.5rem;
    margin-right: -0.5rem; 
     
}
}


@media screen and (max-width: 800px) {
    .h3-class-2 {
        font-size: 20px;
    }

    .h1-class-comp {
        font-size: 3rem;
    }
}

@media screen and (max-width: 767px) {
    .section {
        padding: 3rem 1rem;
    }

    .container {
        flex-direction: column;
    }

    .column, .column.is-half {
        width: 100%;
    }
}


@media screen and (max-width: 700px) {
    .feature{
        max-width: 500px;
    }
}


@media screen and (max-width: 600px) {

    .comparison-bottom {
        flex-direction: column;
        gap: 32px;
    }

    .h3-class-2 {
        font-size: 18px;
    }

    .h1-class-comp {
        font-size: 2.5rem;
    }

    .feature{
        max-width: 400px;
    }

}


@media screen and (max-width: 500px) {
    .feature{
        max-width: 300px;
    }
}


   

