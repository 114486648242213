.article-item {
    width: 100%;
    perspective: 35rem;
    display: flex;
    text-decoration: none;
    align-items: center;
    align-self: stretch;
    background-color: var(--white);
    border-color: var(--white);
    border-radius: 16px;
    box-shadow: var(--double-shadow);
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    transition: transform 0.01s ease;
    /* Add a smooth transition effect */
    transform-origin: center center;
    overflow: hidden;
}
.article-item:hover {
    transform: translate(0, -3px);
    box-shadow: 0 0 15px #0000004d;
}

.article-contents{
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.article-img-wrapper {
    width: 100%;
    height: 16vw;
    max-height: 14rem;
    position: relative;
    overflow: hidden;
    object-fit: cover;
}

.article-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
    border-radius: 16px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.card-content {
    width: 100%;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.5rem;
    display: flex;
    position: relative;
    font-family: Inter, sans-serif;
}

.date {
    width: 100%;
    color: var(--smoke-default);
    letter-spacing: .01rem;
    white-space: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;
    font-size: .83rem;
    font-weight: 500;
    display: flex;
    color: var(--gray-800);
}


.divider {
    width: 100%;
    height: 1px;
    color: #eaecf0;
    background-color: #eaecf0;
    -webkit-text-stroke-color: #eaecf0;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
}

.read-time {
    display: flex;
}

.min-read-txt {
    margin-left: 0.35rem;
}

.title {
    flex: 1;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin: 0.5rem;
    box-sizing: border-box;
    display: block;
    min-height: 120px;
}

.title-text {
    color: var(--primary-darkest);
    text-decoration: none;
    font-weight: var(--h-1-font-weight);
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.35;
    min-height: 52px;
}

.card-bottom {
    width: 100%;
    max-width: 100%;
    grid-column-gap: 0px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    color: var(--gray-800);
    text-decoration: none;
}

.author-2 {
    max-width: 100%;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    display: flex;
    gap: 5px;
}

.author-img {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    overflow: hidden;
}

.image {
    width: 36px;
    height: auto;
}

.author-name {
    font-size: .85rem;
    font-family: Inter, sans-serif;
    font-size: .83rem;
    line-height: 1.5;
}

.tag {
    grid-column-gap: 0.375rem;
    color: #344054;
    white-space: nowrap;
    background-color: #f2f4f7;
    border-radius: 10rem;
    align-items: center;
    font-size: .83rem;
    display: flex;

    text-transform: none;
    padding: 0.25rem 0.75rem;
    font-weight: 500;
    position: static;
}

@media screen and (max-width: 991px) {
    .article-img-wrapper {
        height: 52vw;
    }
}