.frame-22{
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    gap: 64px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.go-back-button {
  display: none;
}

.logo-style {
  width: 109.03px !important;
    /*height: 40.44px !important;*/
    margin-top: -0.21px !important;
    margin-bottom: -0.23px !important;
    position: relative !important;
}

.frame-23 {
    align-items: center;
    justify-content: center;
    align-self: center;
    background-color: var(--background-blue);
    /*border-radius: 16px;*/
    /*box-shadow: var(--double-shadow);*/
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    justify-content: center;
    padding: 36px 72px 72px 72px;
    position: relative;
  }

  .primary-color-form {
    color: var(--primary-darker);
}

  .frame-24 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
  }

  .text-wrapper-6 {
    align-self: stretch;
    color: var(--primary-darkest);
    font-family: var(--h-2-font-family);
    font-size: var(--h-2-font-size);
    font-style: var(--h-2-font-style);
    font-weight: var(--h-2-font-weight);
    letter-spacing: var(--h-2-letter-spacing);
    line-height: var(--h-2-line-height);
    margin-top: -1px;
    position: relative;
  }

.frame-12-form {
  align-items: center;
  align-self: stretch;
  background-color: #F5F7FC;
  display: flex;
  flex: 1;
  flex-direction: column;
  /*gap: 10px;*/
  justify-content: center;
  /*padding: 72px 0px;*/
  position: relative;
  /*width: 100%;*/
  padding-top: 72px;
  height: 100%;
  width: 100%;
  max-width: 50%;
}

.frame-25 {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    justify-content: flex-end;
    position: relative;
    width: 100%;
  }

  .frame-28 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 16px;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  .input-label-wrapper {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 16px;
    justify-content: center;
    position: relative;
   
  }
  .design-component-instance-node-2 {
    align-self: stretch !important;
    display: flex !important;
    flex: 0 0 auto !important;
    width: 100% !important;

  }

  .button-submit {
    flex: 0 0 auto !important;
    cursor: pointer;
    align-self: center;
    width: 100%;
  }

  .h1-form {
    align-self: center;
    font-size: 3rem;
    font-family: Inter, sans-serif;
    font-weight: 600;
    line-height: 1.2;
    color: var(--primary-darkest);
    margin-bottom: 24px;
}

  .form-footer-has-text-centered-mt-10{
    display: flex;
    width: 100%;
    align-self: center;
  }
  

  .input-label-2 {
    align-items: flex-start !important;
    align-self: stretch !important;
    display: flex !important;
    flex: 1 !important;
    flex-grow: 1 !important;
    width: 100% !important;
    height: 100px !important;
  }  

  .design-component-instance-node-3 {
    align-self: stretch !important;
    flex: 1 !important;
    flex-grow: 1 !important;
    width: unset !important;
  
  }

.centered-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Optional: Adjust the height to center vertically in the viewport */
}

.popup {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    bottom: 0;
    /* Adjusted position to appear below the button */
    left: 0;
    background: rgba(0, 0, 0, 0.5);
}

.popup-content {
    border-radius: 8px;
    text-align: center;
    font-family: 'Poppins', 'Helvetica', sans-serif;
    margin-top: auto;
    background: rgba(84, 155, 64, 0.796);
    color: white;
}

.text-wrapper-14-form {
    align-self: center;
    color: var(--primary-darkest);
    font-family: var(--subtitle-font-family);
    font-size: var(--subtitle-font-size);
    font-style: var(--subtitle-font-style);
    font-weight: var(--subtitle-font-weight);
    letter-spacing: var(--subtitle-letter-spacing);
    line-height: var(--subtitle-line-height);
    position: relative;
  }

  .input-label-4 {
    flex: 1 !important;
    flex-grow: 1 !important;
    width: unset !important;
  }

@media screen and (max-width: 1210px) {
    .text-wrapper-6 {

        text-align: center;
        font-size: var(--h-1-font-size);
        align-self: center;
        justify-content: center;
    
      }
}

@media screen and (max-width: 1200px) {
  .frame-23{
    padding: 36px 36px 72px;
  }
}

@media screen and (max-width: 960px) {
    .frame-28 {
        flex-direction: column;
      }

      .input-label-4 {
        width: 100% !important;
      }
}

@media screen and (max-width: 860px) {
    .frame-22 {
        max-width: 750px !important;
        width: 100%;
        padding: 0% 5% 0% 5%;
      }
}


@media screen and (max-width: 700px) {
  .frame-12-form{
    max-width: 100%;
    width: 100%;
  }

  .go-back-button {
    width: fit-content;
          display: block;
          padding: 20px 0 0 20px;
  }
}