.main-not-found-page{
   /* margin: 5%;*/
   background-color: var(--background-blue);
    height: 100vh;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Inter, sans-serif;
    gap: 10px;
}

.h1-not-found{
    font-size: 40px;
}

.p-not-found{
    font-size: 22px;
}

.go-back-home{
    text-decoration: none;
    color: var(--gray-800)
}