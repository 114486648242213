.frame-29 {
    align-items: center;
    align-self: stretch;
    background-color: var(--primary-darkest);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    padding: 32px 1%;
    position: relative;
    width: 100%;
}



.footer {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    max-width: 1200px;
    gap: 80px;
    width: 100%;
}

.comment-bottom {
    flex-direction: row;
    justify-content: space-between;
    align-self: stretch;
    display: flex;
    gap: 10px;
  }
  

  .capterra {
    justify-content: flex-end;
    width:100%;
    min-width: 77px;
    max-width: 120px;
    /* padding: 0 16px; */
    display: flex;
  }
  
  .getapp {
    align-self: flex-start;
    width: 100%;
    min-width: 60px;
    max-width: 80px;
  }
  
  .getapp-img {
    width: 100%;
    height: 100%;
  }

.tb-1-1 {
    height: 40.44px !important;
    position: relative !important;
    width: 109.03px !important;
}

.text-wrapper-15 {
    color: var(--gray-400);
    font-family: var(--bodycopy-font-family);
    font-size: var(--bodycopy-font-size);
    font-style: var(--bodycopy-font-style);
    font-weight: var(--bodycopy-font-weight);
    letter-spacing: var(--bodycopy-letter-spacing);
    line-height: var(--bodycopy-line-height);
    position: relative;
    width: fit-content;
}

.frame-30 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 36px;
    justify-content: center;
    position: relative;
}

.frame-31 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
}

.text-wrapper-16 {
    color: var(--gray-400);
    font-family: var(--bodycopy-font-family);
    font-size: var(--bodycopy-font-size);
    font-style: var(--bodycopy-font-style);
    font-weight: var(--bodycopy-font-weight);
    letter-spacing: var(--bodycopy-letter-spacing);
    line-height: var(--bodycopy-line-height);
    margin-top: -1px;
    position: relative;
    width: 83px;
}

.text-wrapper-compare{
    color: var(--gray-400);
    font-family: var(--bodycopy-font-family);
    font-size: var(--bodycopy-font-size);
    font-style: var(--bodycopy-font-style);
    font-weight: var(--bodycopy-font-weight);
    letter-spacing: var(--bodycopy-letter-spacing);
    line-height: var(--bodycopy-line-height);
    margin-top: -1px;
    position: relative;
    width: 90px;
}

.text-wrapper-17 {
    align-self: stretch;
    color: var(--white);
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    text-decoration: underline;
}

@media screen and (max-width: 1210px) {

    .footer {
        gap: 120px;
    }

}

@media screen and (max-width: 1152px) {
    .footer {
        gap: 30px;
        padding: 0 2%;
    }

}

@media screen and (max-width: 960px) {

    .footer {
        gap: 40px;
    }
}

@media screen and (max-width: 860px) {
    .footer {
        flex-direction: column;
    }

    .text-wrapper-15 {
        text-align: center;
    }
}