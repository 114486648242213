:root {
  --background-blue: rgba(245, 247, 252, 1);
  --bodycopy-font-family: "Inter-regular", sans-serif;
  --bodycopy-font-size: 16px;
  --bodycopy-font-style: normal;
  --bodycopy-font-weight: 400;
  --bodycopy-large-font-family: "Inter-regular", sans-serif;
  --bodycopy-large-font-size: 18px;
  --bodycopy-large-font-style: normal;
  --bodycopy-large-font-weight: 400;
  --bodycopy-large-letter-spacing: 0px;
  --bodycopy-large-line-height: 133.33332538604736%;
  --bodycopy-letter-spacing: 0px;
  --bodycopy-line-height: 150%;
  --cta: rgba(34, 119, 255, 1);
  --double-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.12), 0px 4px 6px 0px rgba(0, 0, 0, 0.1),
    0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  --double-shadow-transparent: 0px 2px 16px 0px rgba(0, 0, 0, 0), 0px 4px 6px 0px rgba(0, 0, 0, 0),
    0px 2px 3px 0px rgba(0, 0, 0, 0);
  --gray-400: rgba(150, 164, 178, 1);
  --gray-800: rgba(95, 107, 119, 1);
  --h-1-font-family: "Inter-Bold", sans-serif;
  --h-1-font-size: 48px;
  --h-1-font-style: normal;
  --h-1-font-weight: 700;
  --h-1-letter-spacing: 0px;
  --h-1-line-height: 114.99999761581421%;
  --h-2-font-family: "Inter-Bold", sans-serif;
  --h-2-font-size: 32px;
  --h-2-font-style: normal;
  --h-2-font-weight: 700;
  --h-2-letter-spacing: 0px;
  --h-2-line-height: 114.99999761581421%;
  --h-3-font-family: "Inter-Bold", sans-serif;
  --h-3-font-size: 32px;
  --h-3-font-style: normal;
  --h-3-font-weight: 700;
  --h-3-letter-spacing: 0px;
  --h-3-line-height: 114.99999761581421%;
  --h-4-font-family: "Inter-Bold", sans-serif;
  --h-4-font-size: 24px;
  --h-4-font-style: normal;
  --h-4-font-weight: 700;
  --h-4-letter-spacing: 0px;
  --h-4-line-height: 114.99999761581421%;
  --intro-font-family: "Inter-Regular", sans-serif;
  --intro-font-size: 20px;
  --intro-font-style: normal;
  --intro-font-weight: 400;
  --intro-letter-spacing: 0px;
  --intro-line-height: 120.00000476837158%;
  --primary: rgba(68, 119, 187, 1);
  --primary-darker: rgba(34, 85, 136, 1);
  --primary-darkest: rgba(0, 51, 85, 1);
  --primary-lighter: rgba(119, 153, 204, 1);
  --primary-lightest: rgba(187, 204, 238, 1);
  --purple-1: rgba(104, 77, 176, 1);
  --purple-light: rgba(165, 145, 215, 1);
  --secondary: rgba(51, 136, 119, 1);
  --secondary-dark: rgba(34, 119, 102, 1);
  --secondary-light: rgba(187, 221, 221, 1);
  --subtitle-font-family: "Inter-Regular", sans-serif;
  --subtitle-font-size: 24px;
  --subtitle-font-style: normal;
  --subtitle-font-weight: 400;
  --subtitle-letter-spacing: 0px;
  --subtitle-line-height: 120.00000476837158%;
  --tb-darkblue: rgba(47, 84, 150, 1);
  --tb-lightblue: rgba(180, 198, 231, 1);
  --white: rgba(255, 255, 255, 1);
}