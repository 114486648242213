.input-custom {
  align-items: flex-end;
  background-color: var(--white);
  border: 1px solid;
  border-radius: 5px;
  display: inline-flex;
  gap: 10px;
  padding: 8px 10px;
  position: relative;
 /*min-height: 40px;*/
}

.input-custom::placeholder {
  color: var(--gray-400);
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.input-custom .PLACEHOLDER {
  color: var(--gray-400);
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.input-custom.default {
  border-color: var(--primary-lightest);
 
}

.input-custom:hover {
  border-color: var(--primary-darker);
  /* Darken the border color on hover */
}

.input-custom.variant-2 {
  border-color: var(--primary);
}