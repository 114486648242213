.quote {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 16px 24px 24px;
  position: relative;
}

.quote .TOP {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
  min-height: 152px;
}

.quote .img {
  flex: 0 0 auto;
  position: relative;
}

.quote .for-our-mobile-app-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  padding: 8px 0px 0px;
  position: relative;
}

.quote .for-our-mobile-app {
  align-self: stretch;
  color: var(--gray-800);
  font-family: var(--bodycopy-large-font-family);
  font-size: var(--bodycopy-large-font-size);
  font-style: var(--bodycopy-large-font-style);
  font-weight: var(--bodycopy-large-font-weight);
  letter-spacing: var(--bodycopy-large-letter-spacing);
  line-height: var(--bodycopy-large-line-height);
  margin-top: -1px;
  position: relative;
}

.quote .frame-2 {
  align-self: stretch;
  flex: 0 0 auto;
  position: relative;
}

.quote .BOTTOM {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
  width: 100%;
}


.quote .div {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  position: relative;
}

.quote .nilay-kose {
  align-self: stretch;
  color: var(--primary-darkest);
  font-family: var(--subtitle-font-family);
  font-size: var(--subtitle-font-size);
  font-style: var(--subtitle-font-style);
  font-weight: var(--subtitle-font-weight);
  letter-spacing: var(--subtitle-letter-spacing);
  line-height: var(--subtitle-line-height);
  position: relative;
}

.quote .test-and-quality {
  align-self: stretch;
  color: var(--gray-800);
  font-family: var(--bodycopy-font-family);
  font-size: var(--bodycopy-font-size);
  font-style: var(--bodycopy-font-style);
  font-weight: var(--bodycopy-font-weight);
  letter-spacing: var(--bodycopy-letter-spacing);
  line-height: var(--bodycopy-line-height);
  position: relative;
}

.quote .avatar-1 {
  height: 80px !important;
  position: relative !important;
  width: 80px !important;
}