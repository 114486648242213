.thank-main{
    background-color: #F5F7FC;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: space-between;
    
}

.frame-wrapper-thank {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    padding: 96px 0;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .frame-4-thank {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    position: relative;
  }

  .p-thank {
    color: var(--gray-800);
    font-family: var(--intro-font-family);
    font-size: var(--intro-font-size);
    font-style: var(--intro-font-style);
    font-weight: var(--intro-font-weight);
    letter-spacing: var(--intro-letter-spacing);
    line-height: var(--intro-line-height);
    position: relative;
    max-width: 451px;
  }
  .div-2-thank {
    align-items: center;
    display: flex;
    gap: 32px;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    max-width: 1152px;
  }

  .div-wrapper-thank {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    /*height: 389px;*/
    justify-content: space-around;
    padding: 0px 64px;
    position: relative;
  }

  .text-wrapper-3-thank {
    color: var(--primary-darkest);
    font-family: var(--h-1-font-family);
    font-size: 52px;
    font-style: var(--h-1-font-style);
    font-weight: var(--h-1-font-weight);
    letter-spacing: var(--h-1-letter-spacing);
    line-height: var(--h-1-line-height);
    margin-top: -1px;
    position: relative;
  }

  .img-wrapper-thank {
    max-height: 355px;
    position: relative;
    max-width: 473px;
    /*margin-top: 8%;*/
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .worker-thank {
    max-width: 100%;
    padding-right: 1%;
  }

  @media screen and (max-width: 960px) {

    .div-wrapper-thank {
        width: 100%;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-left: 5%;
      }
  }


  @media screen and (max-width: 860px) {
    .thank-main{
        height: 100%;
    }

    .div-2-thank {
        flex: none;
        justify-content: space-between;
        align-items: center;
        max-width: 1152px;
        display: flex;
        position: relative;
        flex-direction: column;
        
      }

      .div-wrapper-thank {
        width: 100%;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 5%;
        text-align: center;
      }

      .worker-thank {
        max-width: 80%;
        align-items: center;
        margin: 0 0 0 10px;
        padding-right: 0;
      }

      .frame-4-thank {
        align-items: center;
      }
  }